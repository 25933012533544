<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Job Report</h6>

            <!-- Title -->
            <div>
              <h1 class="header-title" v-if="startDate">
              Vehicle: {{ vehicleId }}
              </h1>
            </div>
            <div v-if="startDate">
              <span class="text-muted">Reporting Period</span>
              <h3 class="header-title" >
                {{ startDate.toDate().toDateString() }}
                to {{ endDate.toDate().toDateString() }}
               </h3>
            </div>

            <!-- <h1 class="header-title">Job Report for {{vehicleId}} - {{ dateRange }}</h1> -->
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <router-link class="btn btn-primary lift"
            :to="{ name: 'TransporterNewJob'}">
              Create Job
            </router-link> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<div class="container-fluid" v-if="dataResults">
  <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total Income</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ revenue }} </span>

              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Revenue</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                  {{ revenue - fuel - expenses - maintenance }}
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Fuel</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ fuel }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-warning mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Expenses</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ expenses }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-trash text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Maintenace</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ maintenance }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-trash text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- Job list below -->
  <div class="row">
      <div class="col-3">
        <!-- Card -->
          <div class="card card-fill">
            <div class="card-body">

              <!-- Title -->
              <h6 class="text-uppercase text-center text-muted my-4">
                Total Revenue
              </h6>

              <!-- Price -->
              <div class="row g-0 align-items-center justify-content-center">
                <div class="col-auto">
                  <div class="h2 mb-0">GH&cent;</div>
                </div>
                <div class="col-auto">
                  <div class="display-2 mb-0">{{ revenue - fuel - expenses - maintenance }}</div>
                </div>
              </div> <!-- / .row -->

              <!-- Period -->
              <div class="h6 text-uppercase text-center text-muted mb-5">
                {{ dateRange }}
              </div>
              <!-- Details -->
              <div class="mb-3">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex
                  align-items-center justify-content-between px-0">
                    <small>Income</small> <small>{{ cost }}</small>
                  </li>
                  <li class="list-group-item d-flex
                  align-items-center justify-content-between px-0">
                    <small>Fuel</small> <small>{{ fuel }}</small>
                  </li>
                  <li class="list-group-item d-flex
                  align-items-center justify-content-between px-0">
                    <small>Job Expenses</small> <small>{{ expenses }}</small>
                  </li>
                  <li class="list-group-item d-flex
                  align-items-center justify-content-between px-0">
                    <small>Job Maintenance</small> <small>{{ maintenance }}</small>
                  </li>
                </ul>
              </div>

                    <!-- Button -->
                    <a href="#!" class="btn w-100 btn-light">
                      Start with Basic
                    </a>
            </div>
          </div>
      </div>
      <div class="col-9">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Jobs</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
                <input type="text" class="form-control" ref="datePicker" v-model="dateRange"
                placeholder="Date Range" data-flatpickr='{"mode": "range"}'>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
                  <select class="form-control" v-model="vehicleId">
                    <option value="GT 7348-21"> GT 7348-21 </option>
                    <option value="GT 7349-21"> GT 7349-21 </option>
                  </select>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a href="#!" class="btn btn-sm btn-white" @click="filterJobs"> Filter </a>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a href="#!" class="btn btn-sm btn-white" @click="jobs">Clear Filter </a>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Status
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Pick Up
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Drop Off
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Distance
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Revenue
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-date"
                    >
                      Pick up date
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="job in dataResults" :key="job.id">
                  <td class="goal-status">
                    <div v-if="job.status === 0">
                      <span class="text-primary">●</span> Booked
                    </div>
                    <div v-if="job.status === 1">
                      <span class="text-warning">●</span> In Progress
                    </div>
                    <div v-if="job.status === 2">
                      <span class="text-success">●</span> Completed
                    </div>
                  </td>
                  <td class="goal-progress">
                    {{ job.pickup }}
                  </td>
                  <td class="goal-date">
                    {{ job.dropoff }}
                  </td>
                  <td class="text-start">
                    {{ job.distance }}
                  </td>
                  <td class="text-start">
                    GH&cent; {{ job.finalRevenue }}
                  </td>
                  <td class="text-start">
                    <time datetime="2018-10-24">{{ job.pickupDate.toDate().toDateString() }}</time>
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'PentermJobDetail', params: { id: job.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                        <router-link class="dropdown-item" v-if="job.status < 2"
                        :to="{ name: 'PentermJobEdit', params: { id: job.id } }">
                          <span class="fe fe-edit text-muted mb-0"></span> Edit
                        </router-link>
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobTracker', params: { id: job.id } }">
                          <span class="fe fe-map-pin text-muted mb-0"></span> Track
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import flatpickr from 'flatpickr';
// import { useRouter } from 'vue-router';
// import * as firebase from 'firebase/app';
// import Timestamp from 'firebase/firestore';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Jobs',
  props: [''],
  setup() {
    // const { user, userDetails, getUserRole } = getUser();
    const { user } = getUser();
    // const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = ref(25);
    const datePicker = ref(null);
    const dateRange = ref('');
    const vehicleId = ref('');
    const totalRevenue = ref(0);
    const revenue = ref(0);
    const cost = ref(0);
    const fuel = ref(0);
    const expenses = ref(0);
    const maintenance = ref(0);
    const startDate = ref('');
    const endDate = ref('');

    // end
    const getDateRange = () => {
      console.log(dateRange.value);
      const dateRangeValue = dateRange.value.split(' ');
      console.log(dateRangeValue);
      console.log('start date', dateRangeValue[0]);
      console.log('end date', dateRangeValue[2]);
    };
    // Totals
    const getTotals = () => {
      dataResults.value.forEach((job) => {
        // console.log(job.finalRevenue);
        // Cost
        cost.value += Number(job.cost);
        console.log('Total Cost', cost.value);
        // Revenue
        revenue.value += Number(job.finalRevenue);
        console.log('Total Revenue', revenue.value);
        // Fuel
        fuel.value += Number(job.finalFuel);
        console.log('Total Fuel', fuel.value);
        // Expenses
        expenses.value += Number(job.jobExpenses);
        console.log('Total Expenses', expenses.value);
        // Maintenance
        maintenance.value += Number(job.jobMaintenace);
        console.log('Total Maintenance', maintenance.value);
      });
    };

    // const startDate = firebase.firestore.Timestamp.fromDate(new Date('2021-07-01'));
    // const endDate = firebase.firestore.Timestamp.fromDate(new Date('2021-07-31'));
    // console.log('unix', startDate);

    const filterJobs = async () => {
      const dateRangeValue = dateRange.value.split(' ');
      startDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[0]));
      endDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[2]));
      try {
        const res = await pentermFirestore.collection('jobs')
          .where('truckId', '==', vehicleId.value)
          .where('pickupDate', '>', startDate.value)
          .where('pickupDate', '<', endDate.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            getTotals();
          });
        dump.value = res;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    const jobs = async () => {
      // const start = new Date('2021-08-01').getTime() / 1000;
      // const start = Date.now();
      // const end = new Date('2021-08-31').getTime() / 1000;
      // .where('createdDate', '<=', '1630723231251')
      try {
        const res = await pentermFirestore.collection('jobs')
          .orderBy('createdDate', 'desc').limit(fetchData.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            // last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    onMounted(() => {
      // jobs();
      flatpickr(datePicker.value, { mode: 'range' });
      // if (getUserRole.value === 'admin') {
      //   jobs();
      // } else if (getUserRole.value === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });
    return {
      user,
      loggedInUser,
      dataResults,
      jobs,
      records,
      disableBtn,
      datePicker,
      vehicleId,
      dateRange,
      getDateRange,
      filterJobs,
      totalRevenue,
      revenue,
      cost,
      fuel,
      expenses,
      maintenance,
      startDate,
      endDate,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  padding: 2px;
}
</style>
