<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
      <div class="row">
              <div class="col-xs-12 col-md-6">
                  <div class="card">
        <div class="card-header text-start">
            <span style="font-size: 1.3rem;">
              <span style="color: Black;">
              <i class="fas fa-car"></i>
              </span>
          </span>&nbsp; Pricing Simulator
        </div>
        <div class="card-body">
          <form class="row g-3" @submit.prevent="getDistanceMatrix">
        <div class="col-md-6">
          <div class="form-floating mb-3">
            <select class="form-select" v-model="category"
            id="floatingSelectGrid" aria-label="Floating label select" required>
              <option v-for="pricing in dataResults" :key="pricing.id"
              :value="pricing.categoryName">
                {{ pricing.categoryName }}
                </option>
            </select>
            <!-- <select class="form-select" v-model="category"
            id="floatingSelectGrid" aria-label="Floating label select" required>
              <option v-for="pricing in dataResults" :key="pricing.id"
              :value="`${pricing.minDistance},${pricing.minDistancePrice},${pricing.priceKm}`">
                {{ pricing.categoryName }}
                </option>
            </select> -->
            <label for="floatingSelectGrid">Select category</label>
          </div>
        </div>
        <div class="col-md-6 text-start">
          <div>From: {{ pickupFrom }}</div>
          <div>To: {{ dropOffAt }}</div>
          <div>Price: GHc {{ computedCost }}</div>
          <div>Distance: {{ distanceTravelled }}</div>
          &nbsp;
        </div>
        <div class="col-md-6">
          <div class="mb-3">
              <label>Origin</label>
                    <vue-google-autocomplete :country="['gh']"
                      ref="pickupLocation"
                      types=""
                      id="pickup"
                      classname="form-control"
                      placeholder="Pickup location"
                      required
                      v-on:placechanged="getPickupAddressData"
                  >
                  </vue-google-autocomplete>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
              <label>Dropoff</label>
                <vue-google-autocomplete :country="['gh']"
                  ref="dropoffLocation"
                  types=""
                  id="dropoff"
                  classname="form-control"
                  placeholder="Dropoff location"
                  required
                  v-on:placechanged="getDropoffAddressData"
              >
              </vue-google-autocomplete>
          </div>
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-primary">Get Price</button>
        </div>
      </form>
        </div>
      </div>
              </div>
              <!-- Second Column -->
              <div class="col-xs-12 col-md-6">
                  <div class="card">
        <div class="card-header text-start text-white bg-secondary">
            <span style="font-size: 1.3rem;">
              <span style="color: White;">
              <i class="fas fa-hand-holding-usd"></i>
              </span>
          </span>&nbsp; Pricing List
        </div>
        <div class="card-body">
          <table class="table table-hover text-start">
        <thead>
          <tr>
            <th scope="col">Vehicle Type</th>
            <th scope="col">Max Weight</th>
            <th scope="col">Minimum Distance</th>
            <th scope="col">Price/Km</th>
            <th scope="col">Price/Km</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pricing in dataResults" :key="pricing.id">
            <td>{{ pricing.categoryName }}</td>
            <td>{{ pricing.maxLoad }}</td>
            <td>{{ pricing.minDistance }}</td>
            <td>{{ pricing.minDistancePrice }}</td>
            <td>{{ pricing.priceKm }}</td>
            <td>
              <span style="font-size: 1.2rem;" v-if="pricing.status === 0">
                  <span style="color: Tomato;">
                      <i class="fas fa-times-circle px-1 cursor"></i>
                  </span>
                </span>
                <span style="font-size: 1.2rem;" v-if="pricing.status === 1">
                  <span style="color: Green;">
                      <i class="far fa-check-circle px-1 cursor"></i>
                  </span>
                </span>
            </td>
          </tr>
        </tbody>
      </table>
        </div>
      </div>
      </div>
      </div>
 </div>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';
// import { GoogleMap, Marker } from 'vue3-google-map';
// AIzaSyAQYnPusf-PONkd0wPCIm3p5AKRACXJHZ8
import VueGoogleAutocomplete from 'vue-google-autocomplete';

import firebase from 'firebase/app';
// import { useRouter } from 'vue-router';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default defineComponent({
  components: {
    // GoogleMap, Marker,
    VueGoogleAutocomplete,
  },
  setup() {
    const center = { lat: 5.608215, lng: -0.17157929 };
    // const clicked = GoogleMap.addListener('click', (mapsMouseEvent) => {
    //   console.log(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
    // });
    const { user } = getUser();
    const long = ref('');
    const lat = ref('');
    const pickup = ref('');
    const dropoff = ref('');
    const pickupDate = ref('');
    const dropoffDate = ref('');
    const jobDetail = ref('');
    const pickupLocation = ref(null);
    const dropoffLocation = ref(null);
    const error = ref('');
    const warning = ref('');
    const tripCost = ref('');
    const fberror = ref('');
    const category = ref('');
    // const calWeight = ref('');
    // const navixyError = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const pickupFrom = ref('');
    const dropOffAt = ref('');
    const distanceTravelled = ref('');
    const computedCost = ref('');
    const dumpAddTrip = ref('');

    //
    const priceSchedules = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((priceSchedule) => (
              { ...priceSchedule.data(), id: priceSchedule.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    priceSchedules();
    //

    const getPickupAddressData = (addressData, placeResultData) => {
      pickup.value = {
        location: placeResultData.name,
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
        country: placeResultData.country,
        locality: placeResultData.locality,
        region: placeResultData.administrative_area_level_1,
      };
    };

    const getDropoffAddressData = (addressData, placeResultData) => {
      dropoff.value = {
        location: placeResultData.name,
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
        country: placeResultData.country,
        locality: placeResultData.locality,
        region: placeResultData.administrative_area_level_1,
      };
    };
    // Variable definitions for depature time
    // Client side config
    const { google } = window;
    const getDistanceMatrix = () => {
      const origin = new google.maps.LatLng(pickup.value.lat, pickup.value.lng);
      const destination = new google.maps.LatLng(dropoff.value.lat, dropoff.value.lng);
      //   const depart = new Date(pickupDate.value);
      // const departTime = depart.getTime() / 1000;
      if (pickup.value.place_id && dropoff.value.place_id) {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            // drivingOptions: {
            //   departureTime: depart,
            // },
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              // console.log(`Error was: ${status}`);
              error.value = 'Please ensure a pickup location, date and destination is selected.';
            } else {
              // console.log(response);
              jobDetail.value = response;
              const getDistance = jobDetail.value.rows[0].elements[0].distance.text;
              const pickupGeoPoint = new firebase.firestore.GeoPoint(
                pickup.value.lat, pickup.value.lng,
              );
              const dropoffGeoPoint = new firebase.firestore.GeoPoint(
                dropoff.value.lat, dropoff.value.lng,
              );
              const addTrip = {
                pickup: pickup.value.location,
                pickupCoordinates: pickupGeoPoint,
                // pickupDate: depart,
                dropoff: dropoff.value.location,
                dropoffCoordinates: dropoffGeoPoint,
                distance: Number(getDistance.replace(' km', '')),
                cost: tripCost.value,
                commodity: category.value.split(','),
              };
              dumpAddTrip.value = addTrip;
              // console.log(user.displayName);

              const totalDistance = Number(getDistance.replace(' km', ''));
              pickupFrom.value = pickup.value.location;
              dropOffAt.value = dropoff.value.location;

              distanceTravelled.value = getDistance;

              // Get data using firebase functions
              const getTripPrice = firebase.functions().httpsCallable('getTripPrice');
              getTripPrice({ vehicle: category.value, distance: totalDistance })
                .then((result) => {
                  // console.log('Functions Price', result.data);
                  if (!result.data.error) {
                    computedCost.value = result.data.computedCost;
                  }
                });
              // End of function to get rate
              if (!fberror.value) {
                // console.log('Added to db');
                // router.push({ name: 'AddRates' });
              }
              // Clear fields after submission
              pickupDate.value = '';
              tripCost.value = '';
              pickupLocation.value.clear();
              dropoffLocation.value.clear();
            }
          },
        );
      } else {
        warning.value = 'Please ensure a pickup location, date and destination is selected.';
      }
    };

    onMounted(() => {
      getDistanceMatrix();
    });

    return {
      center,
      dataResults,
      category,
      computedCost,
      pickupFrom,
      dropOffAt,
      distanceTravelled,
      long,
      lat,
      pickup,
      dropoff,
      getPickupAddressData,
      getDropoffAddressData,
      pickupDate,
      dropoffDate,
      jobDetail,
      pickupLocation,
      dropoffLocation,
      error,
      user,
      fberror,
      getDistanceMatrix,
    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
</style>
