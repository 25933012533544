<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Settings</h6>

            <!-- Title -->
            <h1 class="header-title">Vehicle Categories</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <div class="row">
    <div class="col-xs-12 col-md-6">
<div class="card">
  <div class="card-header text-start">
      <span style="font-size: 1.3rem;">
        <span style="color: Black;">
        <i class="fas fa-car"></i>
        </span>
    </span>&nbsp; {{ action.toUpperCase() }} Vehicle Category
  </div>
  <div class="card-body">
      <!-- New -->
      <div class="text-start py-2">
            <span style="font-size: 2rem;">
            <span style="color: Grey;">
                <i class="far fa-plus-square cursor"
                @click="createItem" v-if="action === 'edit'"></i>
            </span>
           </span>
      </div>
      <!--  -->
    <form class="row g-3" @submit.prevent="submitData(action, itemId)">
  <div class="col-md-6">
    <div class="form-floating mb-3">
        <input type="text" class="form-control" id="category"
        placeholder="flatbed" required v-model="categoryName">
        <label for="category">Category Name</label>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-floating mb-3">
        <input type="number" class="form-control" id="maxload"
        placeholder="flatbed" maxlength="2" required v-model="maxLoad">
        <label for="maxload">Max Load/Tonnes</label>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-floating mb-3">
        <input type="number" class="form-control" id="category"
        placeholder="10" maxlength="2" required v-model="minDistance">
        <label for="category">Min Distance</label>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-floating mb-3">
        <input type="number" class="form-control" id="minrate" step=".01"
        placeholder="10" maxlength="5" required v-model="minDistancePrice">
        <label for="minrate">Price</label>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-floating mb-3">
        <input type="number" class="form-control" id="rate-km" step=".01"
        placeholder="10" required v-model="priceKm">
        <label for="rate-km">Price/Km</label>
    </div>
  </div>
  <div class="col-12">
    <!-- <label for="photo" class="form-label">Photo</label> -->
        <label>
            <span style="font-size: 3rem;">
                <span style="color: Mediumslateblue;">
                <i class="fas fa-camera"></i>
                </span>
            </span>
            <input type="file">
        </label>
    </div>
  <div class="col-12">
    <button type="submit" class="btn btn-primary">Submit</button>
  </div>
</form>
  </div>
</div>
        </div>
        <!-- Second Column -->
        <div class="col-xs-12 col-md-6">
            <div class="card">
  <div class="card-header text-start text-white bg-secondary">
      <span style="font-size: 1.3rem;">
        <span style="color: White;">
        <i class="fas fa-hand-holding-usd"></i>
        </span>
    </span>&nbsp; Pricing List
  </div>
  <div class="card-body">
    <table class="table table-hover text-start">
  <thead>
    <tr>
      <th scope="col">Vehicle Type</th>
      <th scope="col">Max Weight</th>
      <th scope="col">Minimum Distance</th>
      <th scope="col">Price/Km</th>
      <th scope="col">Price/Km</th>
      <th scope="col">&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="pricing in dataResults" :key="pricing.id">
       <td>{{ pricing.categoryName }}</td>
       <td>{{ pricing.maxLoad }} Tonnes</td>
       <td>{{ pricing.minDistance }} Km</td>
       <td>GH&cent;{{ pricing.minDistancePrice }}</td>
       <td>GH&cent;{{ pricing.priceKm }}</td>
       <td>
           <span style="font-size: 1.2rem;" v-if="pricing.status === 0">
            <span style="color: Tomato;">
                <i class="fas fa-times-circle px-1 cursor" @click="updateStatus(pricing.id, 1)"></i>
            </span>
           </span>
           <span style="font-size: 1.2rem;" v-if="pricing.status === 1">
            <span style="color: Green;">
                <i class="far fa-check-circle px-1 cursor" @click="updateStatus(pricing.id, 0)"></i>
            </span>
           </span>
           <span style="font-size: 1.2rem;">
            <span style="color: Mediumslateblue;">
                <i class="fas fa-edit px-1 cursor"
                @click="editItem(pricing.id, pricing.categoryName,
                pricing.maxLoad, pricing.minDistance, pricing.minDistancePrice, pricing.priceKm )">
                </i>
            </span>
           </span>
       </td>
    </tr>
  </tbody>
</table>
  </div>
</div>
        </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
// import { ref } from '@vue/runtime-dom';
// import { useRouter } from 'vue-router';
import firebase from 'firebase';
import getUser from '../../../composables/getUser';
import { timestamp, pentermFirestore } from '../../../firebase/config';

export default {
  name: '',
  props: [''],
  setup() {
    const { user } = getUser();
    // const router = useRouter();
    const categoryName = ref('');
    const maxLoad = ref('');
    const minDistance = ref('');
    const minDistancePrice = ref('');
    const priceKm = ref('');
    const error = ref('');
    const dataResults = ref('');
    const dump = ref('');
    let fileToUpload;
    const imageFile = ref('');
    const imageURL = ref('');
    const action = ref('new');
    const itemId = ref('');

    const createItem = () => {
      action.value = 'new';
      categoryName.value = '';
      maxLoad.value = '';
      minDistance.value = '';
      minDistancePrice.value = '';
      priceKm.value = '';
    };
    const editItem = (id, category, mLoad, mLoadDistance, minPrice, KmPrice) => {
      itemId.value = id;
      action.value = 'edit';
      categoryName.value = category;
      maxLoad.value = mLoad;
      minDistance.value = mLoadDistance;
      minDistancePrice.value = minPrice;
      priceKm.value = KmPrice;
    };

    const submitData = async (mode, id) => {
      if (mode === 'new') {
        const addData = {
          categoryName: categoryName.value,
          maxLoad: maxLoad.value,
          minDistance: minDistance.value,
          minDistancePrice: minDistancePrice.value,
          priceKm: priceKm.value,
          uid: user.value.uid,
          imagePath: '../../img/',
          status: 0,
          createdDate: timestamp(),
          updatedDate: timestamp(),
        };
        try {
          const res = await pentermFirestore.collection('vehicleCategories').add(addData);
          dump.value = res.message;

          // Clear fields
          categoryName.value = '';
          maxLoad.value = '';
          minDistance.value = '';
          minDistancePrice.value = '';
          priceKm.value = '';
        // End
        } catch (err) {
          error.value = err.message;
        }
      } else if (mode === 'edit') {
        const priceRef = pentermFirestore.collection('vehicleCategories').doc(id);
        const res = await priceRef.update({
          categoryName: categoryName.value,
          maxLoad: maxLoad.value,
          minDistance: minDistance.value,
          minDistancePrice: minDistancePrice.value,
          priceKm: priceKm.value,
          updatedByUid: user.value.uid,
          updatedBy: user.value.displayName,
          updatedDate: timestamp(),
        });
        dump.value = res;
      }
    };
    const priceSchedules = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((priceSchedule) => (
              { ...priceSchedule.data(), id: priceSchedule.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    priceSchedules();

    // Image Upload
    const fileUpload = (e) => {
      fileToUpload = e.target.files;
    };
    const getFileInfo = () => {
    //   const fileName = `images/${uuidv4()}.jpg`;
      const fileName = 'test.jpg';
      const storageRef = firebase.storage().ref(fileName).put(fileToUpload[0]);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        dump.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      }, (errors) => { console.log(errors.message); },
      () => { dump.value = 100; },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        imageURL.value = url;
        // console.log(url);
      }));
    };

    const updateStatus = async (vehicle, status) => {
      const priceRef = pentermFirestore.collection('vehicleCategories').doc(vehicle);
      const res = await priceRef.update({
        status: Number(status),
      });
      dump.value = res;
    };

    return {
      user,
      action,
      itemId,
      createItem,
      editItem,
      categoryName,
      maxLoad,
      minDistance,
      minDistancePrice,
      priceKm,
      submitData,
      dataResults,
      imageFile,
      fileUpload,
      getFileInfo,
      updateStatus,
    };
  },
};
</script>
<style>
.element {
  display: inline-flex;
  align-items: center;
}
i.fa-camera {
  margin: 10px;
  cursor: pointer;
  font-size: 30px;
}
i:hover {
  opacity: 0.6;
}
input {
  display: none;
}
.cursor {
    cursor: pointer;
}
</style>
