<template>
<h1>Redirecting</h1>
</template>

<script>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import getUser from '../composables/getUser';

export default {
  setup() {
    const router = useRouter();
    const { user } = getUser();

    onMounted(() => {
      router.push({ name: 'TransporterDashboard' });
    });
    return {
      user,
    };
  },
};

</script>
