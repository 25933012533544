<template>
<!--  -->
<div class="page-body">
  <div class="container col-xl-10 col-xxl-8 px-4 py-5">
    <div class="row align-items-center g-lg-5 py-5">
      <!--  -->
      <!-- <div class="col-md-10 mx-auto col-lg-4">
    <img src="../assets/images/pexels-cottonbro-3206079.jpg" alt=""
        class="rounded mx-auto d-block img-fluid text-start">
        </div> -->
      <!--  -->
      <div class="col-lg-6 text-center text-lg-start penterm-text">
        <div class="alert alert-danger" role="alert">
          Account created, please verify your email address.
          <br />
          You may login but some features will not be available.
        </div>
        <h1 class="display-5 lh-1 mb-2">Penterm Transporter.</h1>
        <p class="col-lg-12 fs-5">
          Hassle free way of managing your jobs and fleet.
        </p>
        <p class="text-start">
          <router-link :to="{ name: 'Signup' }">No Account? Sign Up Now!!</router-link>
          </p>
      </div>
      <div class="col-md-10 mx-auto col-lg-6">
          <h1 class="display-4 lh-1 mb-3 text-center" style="font-size: 250%;">
          Welcome to Penterm</h1>
        <form @submit.prevent="handleSubmit"
        class="p-4 p-md-5 border rounded-3 bg-light" v-if="!passwordResetVisible">
          <div class="form-floating mb-3">
            <input type="email" class="form-control" autocomplete="off"
            v-model="email" required>
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control"
            v-model="password" autocomplete="off" required>
            <label for="floatingPassword">Password</label>
          </div>
          <div class="checkbox mb-3">
            <span @click="showPasswordReset" style="cursor: pointer;">Forgot Password</span>
          </div>
          <div class="checkbox mb-3">
            <small class="error">{{ error }}</small>
          </div>
          <button class="w-100 btn btn-lg btn-penterm-red" type="submit">Login</button>
        </form>
        <!-- Password Reset -->
        <form @submit.prevent="handlePasswordReset"
        class="p-4 p-md-5 border rounded-3 bg-light" v-if="passwordResetVisible">
        <h1 class="display-5">Forgot Password</h1>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" autocomplete="off"
            v-model="email">
            <label for="floatingInput">Email address</label>
          </div>
          <div class="checkbox mb-3">
            <span @click="showPasswordReset" style="cursor: pointer;">Login</span>
          </div>
          <div class="checkbox mb-3">
            <small class="error">{{ error }}</small>
          </div>
          <button class="w-100 btn btn-lg btn-penterm-red" type="submit">Reset Password</button>
          <hr class="my-4">
          <small class="text-muted">Check your email for a password reset message.</small>
        </form>
        <!-- End of Password Reset -->
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { ref } from 'vue';
// import firebase from 'firebase';
import { useRouter, useRoute } from 'vue-router';
import useLogin from '../composables/useLogin';

export default {
  name: 'LoginForm',
  setup() {
    const email = ref('');
    const password = ref('');
    const {
      error, login, passwordReset,
    } = useLogin();
    const router = useRouter();
    const route = useRoute();
    const passwordResetVisible = ref(false);
    // const userAccount = ref('');

    // if (window.location.hostname === 'localhost') {
    //   firebase.functions().useEmulator('localhost', 5001);
    // }

    email.value = route.params.email;

    const handleSubmit = async () => {
      await login(email.value.toLowerCase(), password.value);
      if (!error.value) {
        // context.emit('login');

        router.push({ name: 'TransporterDashboard' });
      }
    };
    const showPasswordReset = () => {
      passwordResetVisible.value = !passwordResetVisible.value;
      error.value = '';
    };

    const handlePasswordReset = async () => {
      await passwordReset(email.value);
      if (!error.value) {
        console.log('Reset email sent');
      } else {
        console.log('error');
      }
    };

    return {
      route,
      email,
      password,
      handleSubmit,
      error,
      passwordResetVisible,
      showPasswordReset,
      handlePasswordReset,
    };
  },
};
</script>
<style scoped>
.page-body {
  background-image: url('../assets/images//gabriel-santos-GBVDilE8yvI-unsplash1.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.penterm-text {
  background-color: white;
  opacity: 90%;
  padding: 10px;
  border-radius: 5px;
}
.login{
    width: 70%;
    max-width: 960px;
    margin: 20px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 30, 0.1);
    background: white;
    text-align: center;

}
.welcome{
  text-align: center;
  padding: 20px 0;
  margin: auto;
}
.welcome form {
  width: 300px;
  margin: 20px auto;
}
.welcome label{
  display: block;
  margin: 20px 0 10px;
}
.welcome input{
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
  outline: none;
  color: #999;
  margin: 10px auto;
}
.welcome button{
  margin: 20px auto;
}
.login-box{
  display: inline-block;
  margin: 10px;
}
</style>
