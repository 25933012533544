<template>
  <div class="row-penterm-white">
    <div class="container">
      <div class="row welcome">
      <div class="col-xs-12 col-md-6 center-row-content">
        <h1 class="display-5 text-start">For Shippers</h1>
        <h1 class="display-6 text-start font-size">
          <p>
            Manage your shippments on one platform and book trucks to move them across
            Ghana without the need for phone calls 24x7x365.
          </p>
          <p>
            Get competitive pricing for hauling your shippment.
          </p>
        </h1>
          <div class="d-grid gap-2 d-md-flex justify-content-md-center">
            <router-link class="nav-link" :to="{ name: 'Shippers'}">
              <button class="btn btn-penterm-red me-md-2" type="button">Get a quote</button>
            </router-link>
            <router-link class="nav-link" :to="{ name: 'Signup'}">
              <button class="btn btn-penterm" type="button">Join us</button>
            </router-link>
          </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <img src="../assets/pexels-rodnae-productions-7464701.jpg" alt=""
        class="rounded mx-auto d-block img-fluid">
      </div>
    </div>
    </div>
  </div>
<!--  -->
<div class="row-penterm-red">
  <div class="container text-start">
      <div class="container px-4 py-5" id="icon-grid">
    <h2 class="pb-2 border-bottom">Features</h2>

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-hand-holding-usd"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Competitive Pricing</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fab fa-algolia"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Advanced Bookings</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-map-marked"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Tracking</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-chart-line"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Advanced Reporting</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

</template>

<script>
export default {
  name: 'Shippers',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import url('../assets/features.css');
.welcome {
  margin: 10px auto;
  padding: 10px;
  background: white;
  border-radius: 4px;
}
/* .container-margin {
  margin-bottom: 5px;
} */
.row-item{
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #bbb;
}
.center-row-content {
  margin: auto;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.btn-margin {
  margin: 5px auto;
  padding: 5px;
}
.font-size{
  font-size: 150%;
}
</style>
