<template>
  <div class="main-content">
  <div class="loader-center" v-if="!dataResult">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- end of spinner -->
    <div class="container-fluid" v-if="dataResult">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Pretitle -->
                  <h6 class="header-pretitle">Job Details</h6>

                  <!-- Title -->
                  <h1 class="header-title">Job No: {{ dataResult.tripId }}</h1>
                </div>
                <div class="col-auto">
                  <!-- Buttons -->
                  <a href="#!" class="btn btn-white lift" @click="createPDF"> Download </a>
                  <router-link :to="{name: 'TransporterJobs'}" class="btn btn-primary ms-2 lift">
                  Back to Job Board
                </router-link>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>

          <!-- Content -->
          <div class="card card-body p-5">
            <!-- / .row -->
            <div class="row">
              <div class="col text-center">
                <!-- Logo -->
                <img
                  src="assets/img/logo.svg"
                  alt="..."
                  class="img-fluid mb-4"
                  style="max-width: 2.5rem"
                />

                <!-- Title -->
                <h2 class="mb-2">Invoice from {{ getCompanyName }}</h2>

                <!-- Text -->
                <p class="text-muted mb-6">Invoice {{ dataResult.tripId }}</p>
              </div>
            </div>
            <!-- / .row -->
            <div class="row">
              <div class="col-12 col-md-6">
                <!-- Heading -->
                <h6 class="text-uppercase text-muted">Invoiced from</h6>

                <!-- Text -->
                <p class="text-muted mb-4">
                  <strong class="text-body">{{ getCompanyName }}</strong> <br />
                  CEO of Good Themes <br />
                  123 Happy Walk Way <br />
                  San Francisco, CA
                </p>

                <!-- Heading -->
                <h6 class="text-uppercase text-muted">Invoiced ID</h6>

                <!-- Text -->
                <p class="mb-4">{{ dataResult.tripId }}</p>
              </div>
              <div class="col-12 col-md-6 text-md-end">
                <!-- Heading -->
                <h6 class="text-uppercase text-muted">Invoiced to</h6>

                <!-- Text -->
                <p class="text-muted mb-4">
                  <strong class="text-body">{{ dataResult.customer }}</strong> <br />
                  {{ dataResult.phone }} <br />
                  {{ dataResult.email }} <br />
                </p>

                <!-- Heading -->
                <h6 class="text-uppercase text-muted">Pick up date</h6>

                <!-- Text -->
                <p class="mb-4">
                  <time>
                    {{ dataResult.pickupDate.toDate().toDateString()}}
                  </time>
                </p>
              </div>
            </div>
            <!-- / .row -->
            <div class="row">
              <div class="col-12">
                <!-- Table -->
                <div class="table-responsive">
                  <table class="table my-4">
                    <thead>
                      <tr>
                        <th class="px-0 bg-transparent border-top-0">
                          <span class="h6">Description</span>
                        </th>
                        <th class="px-0 bg-transparent border-top-0">
                          <span class="h6">&nbsp;</span>
                        </th>
                        <th class="px-0 bg-transparent border-top-0 text-end">
                          <span class="h6">Cost</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="px-0" colspan="3">
                          {{ dataResult.itemDesc }}&nbsp;
                          <span style="font-size: 1.2em">
                            <i class="fas fa-weight-hanging"></i>
                            {{ dataResult.itemWeight }} Tonnes - :
                            {{ dataResult.itemWeightDesc }}
                            <div v-if="dataResult.itemWeightDesc === 'bags'">
                              [ {{ dataResult.noOfBags }}bags @{{
                                dataResult.weightOfBag
                              }} Kg/bag ]
                            </div>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-0" colspan="3">From: {{ dataResult.pickup }}</td>
                      </tr>
                      <tr>
                        <td class="px-0" colspan="3">To: {{ dataResult.dropoff }}</td>
                      </tr>
                      <tr>
                        <td class="px-0">Charged Cost</td>
                        <td class="px-0">&nbsp;</td>
                        <td class="px-0 text-end">GH&cent; {{ dataResult.cost }}</td>
                      </tr>
                      <tr class="text-danger">
                        <td class="px-0">Fuel:</td>
                        <td class="px-0 text-end">- </td>
                        <td class="px-0 text-end">GH&cent; {{ dataResult.finalFuel }}</td>
                      </tr>
                      <tr class="text-danger">
                        <td class="px-0">Expenses:</td>
                        <td class="px-0 text-end">- </td>
                        <td class="px-0 text-end">GH&cent; {{ dataResult.jobExpenses }}</td>
                      </tr>
                      <tr class="text-danger">
                        <td class="px-0">Maintenance:</td>
                        <td class="px-0 text-end">- </td>
                        <td class="px-0 text-end">GH&cent; {{ dataResult.jobMaintenace }}</td>
                      </tr>
                      <tr>
                        <td class="px-0 border-top border-top-2">
                          <strong>Total Revenue</strong>
                        </td>
                        <td
                          colspan="2"
                          class="px-0 text-end border-top border-top-2"
                        >
                          <span class="h3">GH&cent; {{ dataResult.finalRevenue }} </span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>

                <hr class="my-5" />

                <!-- Title -->
                <h6 class="text-uppercase">Notes</h6>

                <!-- Text -->
                <p class="text-muted mb-0">
                  This is a detailed view of a job, not the invoice for the customer.
                </p>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
  <!-- end -->
</template>
<script>
import { ref } from '@vue/runtime-dom';
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';
import { pentermFirestore } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'JobDetail',
  components: {},
  setup() {
    const { getUserRole, getCompanyName } = getUser();
    const router = useRouter();
    const error = ref('');
    const dataResult = ref('');
    const route = useRoute();
    // const router = useRouter();
    const pickupLat = ref('');
    const pickupLng = ref('');
    const tripId = ref('');
    const pickUp = ref('');
    const dropOff = ref('');
    const distance = ref('');
    const pickUpDate = ref('');
    const cost = ref('');
    const customerName = ref('');
    const customerPhone = ref('');
    const customerEmail = ref('');

    const job = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterJobs')
          .doc(route.params.id)
          .get();
        // console.log('waiting for data');
        // console.log(res.data());
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        dataResult.value = res.data();
        pickupLat.value = dataResult.value.pickupCoordinates.latitude;
        pickupLng.value = dataResult.value.pickupCoordinates.longitude;
        tripId.value = res.data().tripId;
        pickUp.value = res.data().pickup;
        pickUpDate.value = res.data().pickupDate.toDate().toDateString();
        dropOff.value = res.data().dropoff;
        distance.value = res.data().distance;
        cost.value = res.data().cost;
        customerName.value = res.data().customer;
        customerPhone.value = res.data().phone;
        customerEmail.value = res.data().email;
        //  Collect fields for update
        // source.value = res.data().source;
        // destination.value = res.data().destination;
        // price.value = res.data().price;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    const createPDF = () => {
      const doc = new JSPDF();
      doc.setFont('helvetica');
      doc.setFontSize(20);
      doc.text(getCompanyName.value, 13, 20);
      doc.setFontSize(14);
      doc.text('Official Receipt', 13, 30);
      doc.setFontSize(12);
      doc.text('To:', 13, 40);
      doc.setFontSize(11);
      doc.text(customerName.value, 13, 45);
      doc.setFontSize(11);
      doc.text(customerPhone.value, 13, 50);
      doc.setFontSize(11);
      doc.text(customerEmail.value, 13, 55);
      // doc.text(customerName.value, 10, 30);
      // doc.save('a4.pdf');
      doc.autoTable({
        margin: { top: 80 },
        head: [[`Job Id: ${tripId.value}`, '', pickUpDate.value]],
        body: [
          ['From', pickUp.value, ''],
          ['To', dropOff.value, ''],
          ['Distance', distance.value, ''],
          ['Cost of job', '', cost.value],
          ['VAT 12.5%', '', Number(cost.value * 0.125)],
          [' ', 'Total GHC', Number(cost.value) + Number(cost.value * 0.125)],
          // ...
        ],
      });

      doc.save(`${customerName.value}-${tripId.value}.pdf`);
    };
    job();

    onMounted(() => {
      if (getUserRole.value === 'admin') {
        job();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });
    return { dataResult, createPDF, getCompanyName };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
}
.job {
  margin: auto;
}
.menu-items {
  display: block;
}
.trips {
  width: 98%;
  max-width: 960px;
  margin: 10px auto;
  border-radius: 4px;
  box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
  background: white;
  padding: 10px;
}
.trips:hover {
  background: #f7f9fb;
  cursor: pointer;
}
.jobdetails {
  display: table-row;
  border: 1px solid #bbb;
}
.pad-body {
  padding: 10px;
  margin: 10px;
}
.cursor {
  cursor: pointer;
}
</style>
