import { ref } from 'vue';
import firebase from 'firebase';
import { pentermAuth, timestamp, pentermFirestore } from '../firebase/config';

const error = ref(null);
const setAccountType = ref('');
const transporterUniqueID = `T${Math.floor(Math.random() * 10)}${Date.now()}`;

const signup = async (email, password, displayName, companyName, companyRegNo, companyTIN) => {
  error.value = null;

  try {
    const res = await pentermAuth.createUserWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Could not complete signup');
    }
    // Update username after sigup into dB
    await res.user.updateProfile({ displayName });
    // set user type
    const setAccessType = firebase.functions().httpsCallable('pentermAccountType');
    setAccessType({
      uid: res.user.uid,
      transporterId: transporterUniqueID,
      transporterName: companyName,
      role: 'admin',
      accessType: 'fleetOperator',
    }).then((result) => {
      setAccountType.value = result.data.status;
    }).catch((err) => {
      setAccountType.value = err.message;
    });

    // End of setting user type

    if (res) {
      const addData = {
        transporterId: transporterUniqueID,
        companyName,
        companyRegNo,
        companyTIN,
        uid: res.user.uid,
        status: 0,
        createdDate: timestamp(),
        updatedDate: timestamp(),
      };
      await pentermFirestore.collection('transporters').add(addData);

      const accountTypeData = {
        transporterId: transporterUniqueID,
        companyName,
        accountType: 'fleetOperator',
        users: [{
          fullName: displayName,
          email,
          role: 'admin',
        }],
      };
      await pentermFirestore.collection('accountTypeACL').add(accountTypeData);
    }

    // Email Verification
    const userVerify = pentermAuth.currentUser;
    const actionCodeSettings = {
      url: 'https://partner.penterm.com/',
      // iOS: {
      //   bundleId: 'com.example.ios',
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12',
      // },
      handleCodeInApp: true,
      // When multiple custom dynamic link domains are defined, specify which
      // one to use.
      // dynamicLinkDomain: 'example.page.link',
    };
    userVerify.sendEmailVerification(actionCodeSettings).then(() => {
      // Email sent.
      // console.log('Email Sent');
    }).catch((err) => {
      // An error happened.
      console.log(err);
    });
    // End
    // console.log(res.user);

    error.value = null;

    return res;
  } catch (err) {
    // console.log(err.message);
    error.value = err.message;
    return error;
  }
};

const useSignup = () => ({ error, signup, setAccountType });

export default useSignup;
