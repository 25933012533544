<template>
<div class="container">
  <h1 class="display-6 text-start" style="font-size: 130%">Team</h1>
  <small class="text-start">
    Add team members who can login. Drivers will have to download the app to login.
  </small>
  <hr>
      <div class="col-sm-12">
        <form class="row g-3" @submit.prevent="addUser">
          <div class="col-md-4 text-start">
            <label class="form-label">Full Name</label>
            <input type="text" class="form-control" v-model="fullName">
          </div>
          <div class="col-md-4 text-start">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="email">
          </div>
          <div class="col-md-4 text-start">
            <label class="form-label">Role</label>
            <select class="form-select" v-model="role">
              <option selected>Choose...</option>
              <option value="staff">Staff</option>
              <option value="driver">Driver</option>
            </select>
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary">Add Member</button>
          </div>
        </form>
      </div>
      <div class="col-sm-12">
        <table class="table table-hover text-start">
          <thead>
            <tr>
              <th>Fullname</th>
              <th>Email</th>
              <th colspan="2">Role</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in transporterUsers" :key="user.email">
              <td>{{ user.fullName }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.role }}</td>
              <td>
                <span style="font-size: 1rem;"
                class="show-cursor"
                @click="removeUser(index)"
                v-if="user.role !== 'admin'">
                  <span style="color: Tomato;">
                    <i class="far fa-times-circle"></i>
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import { onMounted } from 'vue';
// import { firebase } from 'firebase/app';
import { useRouter } from 'vue-router';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Profile',
  setup() {
    const router = useRouter();
    const {
      user, getUserRole, transporterId, transporterUsers, userDetails,
    } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dump = ref('');
    const fullName = ref('');
    const email = ref('');
    const role = ref('');

    const addUser = () => {
      transporterUsers.value = [...transporterUsers.value, {
        fullName: fullName.value, email: email.value, role: role.value,
      }];
      const addUserRef = pentermFirestore.collection('accountTypeACL').doc(userDetails.value.transporterId);
      addUserRef.update({
        users: transporterUsers.value,
      }, ({ merge: true })).then(() => {
        console.log('Updated');
      })
        .catch((err) => console.log(err));
      fullName.value = '';
      email.value = '';
      role.value = '';
    };
    const removeUser = (index) => {
      transporterUsers.value.splice(index, 1);
      const addUserRef = pentermFirestore.collection('accountTypeACL').doc(userDetails.value.transporterId);
      addUserRef.update({
        users: transporterUsers.value,
      }, ({ merge: true })).then(() => {
        console.log('Updated');
      })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      // console.log(userDetails.value.transporterId);
      if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      fullName,
      email,
      role,
      user,
      loggedInUser,
      dump,
      error,
      getUserRole,
      transporterId,
      transporterUsers,
      addUser,
      removeUser,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.dashboard-card{
  border: solid #ccc 1px;
  border-radius: 4px;
  margin: 5px;
}
.side-menu {
  height: 40px;
  vertical-align: middle;
  border: 1px solid #efefef;
  margin: 2px;
}
.side-menu a:hover {
  background-color: #ebedef;
  color: #e55353;
}
.show-cursor {
  cursor: pointer;
}

</style>
