// import 'mdb-vue-ui-kit/css/mdb.min.css';
// Firebase App (the core Firebase SDK) is always required and must be listed first
// import firebase from "firebase/app";
// IfJS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import { createApp } from 'vue';
// import VueGeolocation from 'vue-browser-geolocation';
import './registerServiceWorker';
import router from './router';
import App from './App.vue';
// import './assets/penterm.css';
import { pentermAuth } from './firebase/config';

const cors = require('cors')({ origin: true });

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// const firebaseConfig = {
//   // ...
// };

// createApp(App).use(router)
//   .mount('#app');

let app;

pentermAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(router, cors).mount('#app');
  }
});
