<template>
<div>
<h1 class="display-6 text-start">Corporate Profile<hr></h1>
     <form @submit.prevent="submitData" class="py-2">
       <h6 class="text-start text-danger">Company Details</h6>
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Company Name" required v-model="companyName">
              <label for="floatingInput">Company Name</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="T12312222" required v-model="companyRegNo">
              <label for="floatingInput">Company Registration No.</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Company TIN" required v-model="companyTIN">
              <label for="floatingInput">Company TIN</label>
            </div>
          </div>
          <!-- End of Row -->
        </div>
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Office Location" required v-model="addressLocation">
              <label for="floatingInput">Office Location</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Address Line 1" required v-model="addressLineOne">
              <label for="floatingInput">Address Line 1</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="GA-123-1231" required v-model="addressGPS">
              <label for="floatingInput">Ghana Post Address</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
        </div>
        <!-- Row -->
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="P.o Box 1" required v-model="addressPostal">
              <label for="floatingInput">Postal Address</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Company Phone" required v-model="phone">
              <label for="floatingInput">Phone</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="email" class="form-control"
              placeholder="info@company.com" required v-model="email">
              <label for="floatingInput">Email</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <select class="form-control"
              placeholder="Region" required v-model="region">
                <option value="">Select</option>
                <option value="Oti Region">Oti Region</option>
                <option value="Bono East Region">Bono East Region</option>
                <option value="Ahafo Region">Ahafo Region</option>
                <option value="Bono Region">Bono Region</option>
                <option value="North East  Region">North East Region</option>
                <option value="Savannah Region">Savannah Region</option>
                <option value="Western North Region">Western North Region</option>
                <option value="Western Region">Western Region</option>
                <option value="Volta Region">Volta Region</option>
                <option value="Greater Accra Region ">Greater Accra Region </option>
                <option value="Eastern Region">Eastern Region</option>
                <option value="Ashanti Region">Ashanti Region</option>
                <option value="Northern Region">Northern Region </option>
                <option value="Central Region">Central Region</option>
                <option value="Upper East Region">Upper East Region</option>
                <option value="Upper West Region">Upper West Region </option>
              </select>
              <label for="floatingInput">Region</label>
            </div>
          </div>
          <!--  -->
        </div>
      <h6 class="text-start text-danger">Company Contact Details</h6>
 <!-- Company ContactRow -->
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Company Rep1" required v-model="companyRepAName">
              <label for="floatingInput">Company Rep1</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Customer Phone" required v-model="companyRepAPhone">
              <label for="floatingInput">Phone</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="email" class="form-control"
              placeholder="user1@example.com" required v-model="companyRepAEmail">
              <label for="floatingInput">Email</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Designation" required v-model="companyRepADesignation">
              <label for="floatingInput">Designation</label>
            </div>
          </div>
          <!--  -->
        </div>
 <!-- Company ContactRow -->
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Company Rep2" required v-model="companyRepBName">
              <label for="floatingInput">Company Rep2</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="02412345678" required v-model="companyRepBPhone">
              <label for="floatingInput">Phone</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="email" class="form-control"
              placeholder="user2@example.com" required v-model="companyRepBEmail">
              <label for="floatingInput">Email</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Designation" required v-model="companyRepBDesignation">
              <label for="floatingInput">Designation</label>
            </div>
          </div>
          <!--  -->
        </div>
<!--  -->
      <h6 class="text-start text-danger">Company Banking Details</h6>
      <!-- Banking Row -->
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Bank Name" required v-model="bankName">
              <label for="floatingInput">Bank Name</label>
            </div>
          </div>
          <!--  -->
        <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Branch" required v-model="bankBranch">
              <label for="floatingInput">Branch</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Account Name" required v-model="accountName">
              <label for="floatingInput">Account Name</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              placeholder="Account Number" required v-model="accountNumber">
              <label for="floatingInput">Account Number</label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <h6 class="text-start text-danger">Company Documents Submitted</h6>
          <div class="col-xs-4 col-md-6">
            Cerificate of InCorporation
              <label>
                <span style="font-size: 3rem;">
                    <span style="color: Mediumslateblue;">
                    <i class="fas fa-camera"></i>
                    </span>
                </span>
                <input type="file" class="file-input">
            </label>
          </div>
          <div class="col-xs-4 col-md-6">
            TIN Registration
              <label>
                <span style="font-size: 3rem;">
                    <span style="color: Mediumslateblue;">
                    <i class="fas fa-camera"></i>
                    </span>
                </span>
                <input type="file" class="file-input">
            </label>
          </div>
          <!--  -->
        </div>
      <!-- Submit -->
              <div class="row">
          <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
          <div class="col-xs-12 col-md-12">
            <button class="btn btn-penterm-default">Submit</button>
          </div>
        </div>
    <!-- form end -->
      </form>
</div>
<!-- <div v-else>
  <button class="btn btn-penterm-default">Dashboard</button>
</div> -->
</template>

<script>
import {
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import getUser from '../../../composables/getUser';
import { timestamp, pentermFirestore } from '../../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const { user, getCompanyName } = getUser();
    const router = useRouter();
    // const fberror = ref('');
    const collectionName = 'transporters';
    const pageName = ref('');
    const companyName = ref('');
    const companyRegNo = ref('');
    const companyTIN = ref('');
    const addressLocation = ref('');
    const addressLineOne = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const phone = ref('');
    const email = ref('');
    const region = ref('');
    const bankName = ref('');
    const bankBranch = ref('');
    const accountName = ref('');
    const accountNumber = ref('');
    const companyRepAName = ref('');
    const companyRepADesignation = ref('');
    const companyRepAPhone = ref('');
    const companyRepAEmail = ref('');
    const companyRepBName = ref('');
    const companyRepBDesignation = ref('');
    const companyRepBPhone = ref('');
    const companyRepBEmail = ref('');
    const companyRegDoc = ref('');
    const companyTINDoc = ref('');
    const acceptedTerms = ref('');
    // const addAccountData = ref('');
    // const addAccountACL = ref('');
    const error = ref('');

    const submitData = async () => {
      const addData = {
        transporterId: `T${Math.floor(Math.random() * 10)}${Date.now()}`,
        companyName: companyName.value,
        companyRegNo: companyRegNo.value,
        companyTIN: companyTIN.value,
        addressLocation: addressLocation.value,
        addressLineOne: addressLineOne.value,
        addressGPS: addressGPS.value,
        addressPostal: addressPostal.value,
        phone: phone.value,
        email: email.value,
        region: region.value,
        bankName: bankName.value,
        bankBranch: bankBranch.value,
        accountName: accountName.value,
        accountNumber: accountNumber.value,
        companyRepAName: companyRepAName.value,
        companyRepADesignation: companyRepADesignation.value,
        companyRepAPhone: companyRepAPhone.value,
        companyRepAEmail: companyRepAEmail.value,
        companyRepBName: companyRepBName.value,
        companyRepBDesignation: companyRepBDesignation.value,
        companyRepBPhone: companyRepBPhone.value,
        companyRepBEmail: companyRepBEmail.value,
        companyRegDoc: companyRegDoc.value,
        companyTINDoc: companyTINDoc.value,
        acceptedTerms: acceptedTerms.value,
        uid: user.value.uid,
        status: 0,
        createdDate: timestamp(),
        updatedDate: timestamp(),
      };
      await pentermFirestore.collection(collectionName).add(addData);
      // .then((docRef) => {
      //   // console.log('yay', docRef.id);
      //   addAccountData.value = docRef.id;
      // }).catch((err) => {
      //   fberror.value = err;
      // });

      const accountTypeData = {
        transporterId: `T${Math.floor(Math.random() * 10)}${Date.now()}`,
        companyName: companyName.value,
        accountType: 'fleetOperator',
        users: [{
          fullName: user.value.displayName,
          email: user.value.email,
          role: 'admin',
        }],
      };
      await pentermFirestore.collection('accountTypeACL').add(accountTypeData);
      router.go({ name: 'TransporterVehicles' });
      // .then((docRef) => {
      //   // console.log('yeo', docRef.id);
      //   addAccountACL.value = docRef.id;
      // }).catch((err) => {
      //   console.log(err);
      // });
    };
    // submitData();
    // if (addAccountData.value.length > 0) {
    //   router.push({ name: 'TransporterVehicles' });
    //   // console.log('Added to db');
    //   // Clear fields after submission

    //   // Redirect after adding data
    //   // router.push({ name: 'Transporters' });
    // }

    return {
      error,
      user,
      getCompanyName,
      router,
      pageName,
      submitData,
      companyName,
      companyRegNo,
      companyTIN,
      addressLocation,
      addressLineOne,
      addressGPS,
      addressPostal,
      phone,
      email,
      region,
      bankName,
      bankBranch,
      accountName,
      accountNumber,
      companyRepAName,
      companyRepADesignation,
      companyRepAPhone,
      companyRepAEmail,
      companyRepBName,
      companyRepBDesignation,
      companyRepBPhone,
      companyRepBEmail,
      companyRegDoc,
      companyTINDoc,
      acceptedTerms,
    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
</style>
