import { ref } from 'vue';
import { pentermFirestore } from '../firebase/config';

const error = ref('');
const computedCost = ref('');
const getPrice = async (vehichle, distance) => {
  try {
    await pentermFirestore.collection('vehicleCategories').where('categoryName', '==', vehichle).get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const categoryDetails = doc.data();
          //   Compute Distance
          if (distance <= categoryDetails.minDistance) {
            // console.log('Min Distance price GHc', categoryDetails.minDistancePrice);
            computedCost.value = categoryDetails.minDistancePrice;
          } else {
            const disDiff = distance - Number(categoryDetails.minDistance);
            const priceDiff = disDiff * categoryDetails.priceKm;
            const finalPrice = priceDiff + Number(categoryDetails.minDistancePrice);
            computedCost.value = finalPrice.toFixed(2);
            // console.log('Inside Func...', finalPrice.toFixed(2));
            // console.log('Min Distance price GHc', finalPrice);
          }
        });
      });
  } catch (err) {
    error.value = err.message;
    return error;
  }
  return computedCost;
};

const usePrice = () => ({
  getPrice,
});

export default usePrice;
