<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
<div class="container">
  <h1 class="display-6 text-start">Accepted Loadboard</h1>
    <div class="text-end px-2">
      <button class="btn btn-penterm-default" @click="refreshData">
        <i class="fas fa-sync-alt"></i> Refresh Data
      </button>
  </div>
</div>
<div v-if="dataResults">
  <div class="card-body" >
    <table class="table table-hover text-start">
  <thead>
    <tr>
      <th scope="col">Pickup</th>
      <th scope="col">Distance</th>
      <th scope="col">Dropoff</th>
      <th scope="col">Details</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="trip in dataResults" :key="trip.id">
      <td>
        <div>
          {{ trip.pickup }}
        </div>
        <div class="job-date">
          {{ trip.pickupDate.toDate().toDateString() }}
        </div>
      </td>
      <td>
        <div>{{ trip.distance }}</div>
        <div>
          <span style="font-size: 1em; color: Tomato;">
            <i class="fas fa-arrow-right"></i>
          </span>
        </div>
      </td>
      <td>{{ trip.dropoff }}</td>
      <td>
        <ul>
          <li class="nav-item" v-if="trip.status === 1">
            <router-link
            :to="{ name: 'TransporterLoadBoardEdit', params: { id: trip.id} }">
              <button class="btn btn-primary btn-sm">Assign Vehicle</button>
            </router-link>
          </li>
        </ul>
        </td>
    </tr>
  </tbody>
</table>
  </div>
</div>
</template>
<script>
import { onMounted } from 'vue';
import { ref } from '@vue/runtime-dom';
import { useRouter } from 'vue-router';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Jobs',
  props: [''],
  setup() {
    const router = useRouter();
    const { user, transporterId, getUserRole } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    // const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = 25;

    // end
    const jobs = async () => {
      try {
        await pentermFirestore.collection('loadBoard').where('transporterId', '==', transporterId.value)
          .where('driverEmail', '==', user.value.email)
          .orderBy('updatedDate', 'desc')
          .limit(fetchData)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, ' => ', doc.data());
              dataResults.value = [...dataResults.value, ({ ...doc.data(), id: doc.id })];
            });
          });
        // console.log(dataResults.value);
      } catch (err) {
        error.value = err;
      }
    };

    const acceptJob = (job) => {
      const updateJobRef = pentermFirestore.collection('loadBoard').doc(job);
      updateJobRef.update({
        transporterId: transporterId.value,
      }).catch((err) => console.log(err));
    };

    const refreshData = () => {
      dataResults.value = '';
      jobs();
    };

    onMounted(() => {
      if (getUserRole.value === 'driver') {
        jobs();
      } else if (getUserRole.value === 'admin') {
        router.push({ name: 'Jobs' });
      }
    });
    return {
      user,
      loggedInUser,
      dataResults,
      records,
      disableBtn,
      refreshData,
      acceptJob,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  padding: 2px;
}
</style>
