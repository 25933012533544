<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Vehicle Owner</h6>

            <!-- Title -->
            <h1 class="header-title">{{ dataResults.ownerFullName }}</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
           <button class="btn btn-danger" @click="showEditForm" v-if="!editForm">Edit</button>

           <button class="btn btn-secondary"
           @click="showEditForm" v-if="editForm">Cancel Edit</button>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Jobs</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ totalVehicles }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Vehicles</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                  {{ totalVehicles }}
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-truck text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Maintenace Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 50 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-cpu text-warning mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Renewals</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 10 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shield text-danger mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
<!-- edit Details -->
  <div class="row py-2" v-if="editForm">
    <div class="col-sm-12 col-md-12 text-end">
      <button class="btn btn-secondary" @click="showEditForm">Close</button>
    </div>
  </div>
    <EditVehicleForm v-if="editForm"
    :id="route.params.id"
    />
<!-- .end -->
    <!-- Detail -->
      <div class="row" v-if="!formStatus">
        <div class="col-8 col-lg-8 col-xl">
           <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Fleet</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
              </div>
                <div class="col-auto">
                  <button class="btn btn-sm btn-white"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Add Vehicle"
                    @click="showForm(dataResults.ownerEmail, dataResults.ownerFullName)">
                      Add Vehicle
                  </button>
                </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    &nbsp;
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted "
                    >

                      License Plate
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted "
                    >
                      Driver
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted "
                    >
                      Inusrance Expiry
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted "
                    >
                      MoT Expiry
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="vehicle in ownerVehicles" :key="vehicle.id">
                  <td>
                      <span style="font-size: 1.2rem;" v-if="vehicle.driverSex === 'Female'">
                        <span style="color: Pink;">
                            <i class="fas fa-female"></i>
                        </span>
                      </span>
                      <span style="font-size: 1.2rem;" v-if="vehicle.driverSex === 'Male'">
                        <span style="color: Mediumslateblue;">
                            <i class="fas fa-male"></i>
                        </span>
                      </span>
                      <span style="font-size: 1.2rem;" v-if="vehicle.driverSex === 'Other'">
                        <span style="color: Mediumslateblue;">
                            <i class="fas fa-restroom"></i>
                        </span>
                      </span>
                  </td>
                  <td class="goal-status">
                    {{ vehicle.vehicleReg }}
                  </td>
                  <td class="goal-progress">
                    {{ vehicle.driverFullName }}
                  </td>
                  <td class="goal-date">
                    <span style="font-size: 1.2rem;" v-if="vehicle.insuranceEnd.toDate() < today">
                        <span style="color: Red;">
                            <i class="fas fa-times-circle px-1"></i>
                        </span>
                      </span>
                      <span style="font-size: 1.2rem;" v-else>
                        <span style="color: Green;">
                            <i class="far fa-check-circle px-1"></i>
                        </span>
                      </span>
                      {{ vehicle.insuranceEnd.toDate().toDateString() }}
                  </td>
                  <td class="text-start">
                    <span style="font-size: 1.2rem;" v-if="vehicle.motEnd.toDate() <= today">
                              <span style="color: Red;">
                                  <i class="fas fa-times-circle px-1"></i>
                              </span>
                            </span>
                            <span style="font-size: 1.2rem;" v-else>
                              <span style="color: Green;">
                                  <i class="far fa-check-circle px-1"></i>
                              </span>
                            </span>
                            {{ vehicle.motEnd.toDate().toDateString() }}
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterVehicleDetail', params: { id: vehicle.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterVehicleEdit', params: { id: vehicle.id } }">
                          <span class="fe fe-edit text-muted mb-0"></span> Edit
                        </router-link>
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterVehicleEdit', params: { id: vehicle.id } }">
                          <span class="fe fe-map-pin text-muted mb-0"></span> Track
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--  -->
        </div>
        <!-- Detailed Info -->
        <div class="col-4 col-lg-4">
           <div class="card">
              <div class="card-body">
                <!-- Details -->
                <h1>Account Detail</h1>
                  <div>
                    <span class="text-muted">Full Name:</span> {{ dataResults.ownerFullName }}
                  </div>
                  <div>
                    <span class="text-muted">Email:</span> {{ dataResults.ownerEmail }}
                  </div>
                  <div>
                    <span class="text-muted">Phone:</span> {{ dataResults.ownerPhone }}
                  </div>
                  <div>
                    <span class="text-muted">Full Name:</span> {{ dataResults.ownerFullName }}
                  </div>
              </div>
           </div>
        </div>
      </div>
      <!--  -->
      <div class="row py-2" v-if="formStatus">
        <div class="text-end ">
          <button class="btn btn-secondary" @click="showForm">
            <i class=""></i> Close</button>
        </div>
      </div>
        <!--  -->
  </div>
<OwnerVehicleForm
:email="dataResults.ownerEmail"
:owner="dataResults.ownerFullName"
:id="route.params.id"
v-if="formStatus"/>
<!-- end -->
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import { useRoute } from 'vue-router';
import getUser from '../../composables/getUser';
import { pentermFirestore } from '../../firebase/config';
import OwnerVehicleForm from './OwnerVehicleFormNew.vue';
import EditVehicleForm from './EditOwnerForm.vue';

export default {
  name: 'OwnerVehicleList',
  components: {
    OwnerVehicleForm,
    EditVehicleForm,
  },
  setup() {
    const { user } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dump = ref('');
    const route = useRoute();
    const dataResults = ref([]);
    const formStatus = ref(false);
    const ownerEmail = ref(false);
    const ownerFullName = ref(false);
    const ownerVehicles = ref();
    const totalVehicles = ref('');
    const today = ref('');
    const editForm = ref(false);
    const editFormText = ref('Edit');

    const showForm = () => {
      formStatus.value = !formStatus.value;
      editForm.value = false;
      // ownerEmail.value = owner;
      // ownerFullName.value = fullname;
    };

    const showEditForm = () => {
      editForm.value = !editForm.value;
      formStatus.value = false;
      editFormText.value = 'Close Edit Form';
    };

    const getOwners = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicleOwners').doc(route.params.id).get();
        // console.log('waiting for data');
        dump.value = res;
        if (res) {
          dataResults.value = res.data();
          // ownerEmail.value = res.data().ownerEmail;

          // Get Vehicle Owners
          const vehicles = async () => {
            try {
              const result = await pentermFirestore.collection('transporterVehicles')
              // .where('transporterId', '==', userDetails.value.transporterId)
                .where('ownerEmail', '==', res.data().ownerEmail)
                .orderBy('createdDate', 'desc')
                .limit(25)
                .onSnapshot((snap) => {
                  ownerVehicles.value = snap.docs.map((vehicle) => (
                    { ...vehicle.data(), id: vehicle.id }
                  ));
                  // last = snap.docs[snap.docs.length - 1];
                  totalVehicles.value = snap.docs.length;
                  // console.log(last.data());
                  // disableBtn.value = false;
                });
              // console.log('waiting for data');
              dump.value = result;
            } catch (err) {
              error.value = err.message;
            }
          };
          vehicles();
          // End of getting vehicles
        }
      } catch (err) {
        error.value = err.message;
      }
    };

    onMounted(() => {
      getOwners();
    });

    return {
      user,
      route,
      today,
      loggedInUser,
      dump,
      error,
      formStatus,
      showForm,
      ownerEmail,
      ownerFullName,
      dataResults,
      ownerVehicles,
      totalVehicles,
      editFormText,
      editForm,
      showEditForm,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.dashboard-card{
  border: solid #ccc 1px;
  border-radius: 4px;
  margin: 5px;
}
.side-menu {
  height: 40px;
  vertical-align: middle;
  border: 1px solid #efefef;
  margin: 2px;
}
.side-menu a:hover {
  background-color: #ebedef;
  color: #e55353;
}

</style>
