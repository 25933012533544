<template>
<div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide-to="0"
    class="active" aria-current="true" aria-label="Slide 1"></button>
    <button
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../assets/welcome_bg.jpg" class="d-block w-100" alt="..." height="300">
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/product_1.jpg" class="d-block w-100" alt="..." height="300">
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/product_2.jpg" class="d-block w-100" alt="... " height="300">
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
  </div>
  <button
  class="carousel-control-prev"
  type="button"
  data-bs-target="#carouselExampleCaptions"  data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
  class="carousel-control-next"
  type="button"
  data-bs-target="#carouselExampleCaptions"  data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

    <h1 class="display-4">Choose from our selection of trucks for sale</h1>
    <p class="lead">We offer competitive pricing for your frieght and visibiity all the way.</p>
  </div>
</template>
