<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">{{ getCompanyName }} Vehicle Registration</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<!--  -->
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
  <!--  -->
  <h4 class=" text-start">Form<hr></h4>
  <!-- <button class="btn btn-primary" @click="uploadImages">Click me</button> -->
      <form @submit.prevent="submitData" autocomplete="off" class="py-2">
        <div class="row error" v-if="dobError || licenseError || insuranceError">
          <div class="col-xs-12 col-md-12">
            <ul>
              <li v-if="dobError">{{ dobError }}</li>
              <li v-if="licenseError">{{ licenseError }}</li>
              <li v-if="insuranceError">{{ insuranceError }}</li>
            </ul>
          </div>
        </div>
        <h4 class=" text-start text-danger">Driver Details</h4>
          <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-2">
              <div class="form-floating mb-2">
                <select class="form-control" required v-model="driverSex">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                <label>Sex <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-4">
              <div class="form-floating mb-4">
                <input type="text" class="form-control"
                required v-model="driverFullName">
                <label>Driver full name <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control" :class="[dobErrorStyle]"
                required v-model="driverDoB" :max="dobMaxYear"
                @change="getNumberOfDays(driverDoB, new Date(),'DoB')">
                <label>Date Of Birth <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="tel" class="form-control"
                required v-model="driverPhone">
                <label>Phone Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <!-- end of row -->
          </div>
          <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <!-- if driver exists -->
              <small v-if="driverData.length > 0">Driver Already Registered</small>
              <!--  -->
              <div class="form-floating mb-3">
                <input type="email" class="form-control"
                v-model="driverEmail" required @keyup="getdriverData">
                <label>Email <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control" required v-model="driverLicenseType">
                  <option value="B">Class B</option>
                  <option value="C">Class C</option>
                  <option value="D">Class D</option>
                  <option value="E">Class E</option>
                  <option value="F">Class F</option>
                </select>
                <label>License Type <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="driverLicenseNumber">
                <label>License Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control"
                required v-model="driverLicenseExpiry"
                :class="[licenseErrorStyle]"
                @change="getNumberOfDays(new Date(), driverLicenseExpiry, 'License')">
                <label>License Expiry <span class="required-field"> *</span></label>
              </div>
            </div>
            <!-- End of Row -->
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="addressGPS">
                <label>GhanaPost Address <span class="required-field"> *</span></label>
              </div>
            </div>
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                v-model="addressPostal">
                <label>Post Box [optional]</label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="driverRegion">
                  <option value="">Select</option>
                  <option value="Oti Region">Oti Region</option>
                  <option value="Bono East Region">Bono East Region</option>
                  <option value="Ahafo Region">Ahafo Region</option>
                  <option value="Bono Region">Bono Region</option>
                  <option value="North East  Region">North East Region</option>
                  <option value="Savannah Region">Savannah Region</option>
                  <option value="Western North Region">Western North Region</option>
                  <option value="Western Region">Western Region</option>
                  <option value="Volta Region">Volta Region</option>
                  <option value="Greater Accra Region ">Greater Accra Region </option>
                  <option value="Eastern Region">Eastern Region</option>
                  <option value="Ashanti Region">Ashanti Region</option>
                  <option value="Northern Region">Northern Region </option>
                  <option value="Central Region">Central Region</option>
                  <option value="Upper East Region">Upper East Region</option>
                  <option value="Upper West Region">Upper West Region </option>
                </select>
                <label>Region <span class="required-field"> *</span></label>
              </div>
            </div>
          </div>
          <!-- Row -->
          <!--  -->
      <div class="row">
        <!-- Vehicle Types -->
        <h4 class=" text-start text-danger">Vehicle Details:</h4>
        <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-1">
          <div class="col" v-for="pricing in dataResults" :key="pricing.id">
            {{ pricing.categoryName }}
            <input type="radio" :value="pricing.categoryName"
            v-model="categoryPicked" required name="vehicleType"/>
            <div class="vehicle-type">
              <img :src="`../../../img/${pricing.imagePath}`" height="40">
              <!-- <img :src=pricing.imagePath height="40"> -->
            </div>
          </div>
        </div>
        <div class="row py-2">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="vehicleMake">
                  <option value="">Select</option>
                  <option value="Scania">Scania</option>
                  <option value="Hino">Hino</option>
                  <option value="Iveco">Iveco</option>
                  <option value="MAN">MAN</option>
                  <option value="Volvo">Volvo</option>
                  <option value="Dongfeng">Dongfeng</option>
                  <option value="TATA">TATA</option>
                  <option value="Volkswagen">Volkswagen</option>
                  <option value="Daimler">Daimler</option>
                </select>
                <label>Manufacturer <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="vehicleReg">
                <label>Registration No. <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="vehicleVIN">
                <label>Chassis Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
              <select class="form-control"
                required v-model="vehicleMakeYear">
                <option v-for="year in makeYear" :key="year" :value="year">{{ year }}</option>
              </select>
                <label>Year <span class="required-field"> *</span></label>
              </div>
            </div>
            </div>
        <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="insuranceCompany">
                  <option value="">Select</option>
                <option value="Activa International">Activa International </option>
                <option value="Allianz Insurance">Allianz Insurance</option>
                <option value="Bedrock Insurance">Bedrock Insurance</option>
                <option value="Best Assurance">Best Assurance</option>
                <option value="Donewell Insurance">Donewell Insurance</option>
                <option value="Enterprise Insurance">Enterprise Insurance</option>
                <option value="Ghana Union Assurance">Ghana Union Assurance </option>
                <option value="Glico General Insurance">Glico General Insurance</option>
                <option value="Hollard Insurance">Hollard Insurance</option>
                <option value="Imperial General Assurance">Imperial General Assurance</option>
                <option value="Loyalty Insurance">Loyalty Insurance </option>
                <option value="Millennium Insurance">Millennium Insurance</option>
                <option value="NSIA Insurance">NSIA Insurance</option>
                <option value="Phoenix Insurance">Phoenix Insurance</option>
                <option value="Prime Insurance ">Prime Insurance</option>
                <option value="Priority Insurance">Priority Insurance</option>
                <option value="Provident Insurance">Provident Insurance</option>
                <option value="Quality Insurance">Quality Insurance</option>
                <option value="RegencyNem Insurance">RegencyNem Insurance</option>
                <option value="Saham Insurance">Saham Insurance</option>
                <option value="Serene Insurance">Serene Insurance</option>
                <option value="SIC Insurance">SIC Insurance</option>
                <option value="Star Assurance">Star Assurance</option>
                <option value="SUNU Assurances">SUNU Assurances</option>
                <option value="Unique Insurance">Unique Insurance</option>
                <option value="Vanguard Assurance">Vanguard Assurance</option>
                <option value="Wapic Insurance">Wapic Insurance</option>
                </select>
                <label>Insurance Company<span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="insuranceType">
                  <option value="">Select</option>
                  <option value="Comprehensive">Comprehensive</option>
                  <option value="3rd-Party">3rd-Party</option>
                </select>
                <label>Insurance Type <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control" :min="minDate" :max="maxDate"
                :class="[insuranceErrorStyle]"
                required v-model="insuranceStart"
                @change="getNumberOfDays(insuranceStart, insuranceEnd, 'Insurance')">
                <label>Insurance Start</label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control"
                :class="[motErrorStyle]"
                required v-model="motStart" :min="minDate" :max="maxDate"
                @change="getNumberOfDays(motStart, motEnd, 'MoT'), getEndDate(motStart)">
                <label>Road Worthiness Start</label>
              </div>
            </div>
            </div>
        <!-- End of vehicle types -->
      </div>
      <h4 class=" text-start text-danger">
        Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
        </h4>
            <div class="row py-2">
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Goods in Transit Insurance
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" id="git"
                  name="Goods in Transit Insurance" required class="form-control"
                  capture="user" accept="image/*" @change="fileUpload" ref="docName">
              </label>
            </div>
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Insurance Certificate
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="insurancePhoto" name="Insurance Certificate" required
                  capture="user" accept="image/*"
                  @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Road Worthy Certificate
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="roadWorthyPhoto" name="Road Worthy Certificate" required
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            <!-- License -->
            </div>
            <div class="row">
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Passport/GhanaCard
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="ghanaID" name="Ghana ID" required
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Drivers License Front
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoA" name="Drivers License Front"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Drivers License Back
                <span class="required-field"> *</span>
              </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoB" name="Drivers License Back"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              &nbsp;
            </div>
            <div class="col-xs-4 col-md-3">
              &nbsp;
            </div>
            <!--  -->
            </div>
        <!-- Submit -->
                <div class="row">
            <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
            <div class="col-xs-12 col-md-12 text-center" v-if="driverData.length === 0">
              <button class="btn btn-danger">Submit</button>
            </div>
          </div>
      <!-- form end -->
        </form>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
// import {
//   getStorage, useStorageEmulator, ref as imgRef, uploadBytes,
// } from 'firebase/storage';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const {
      user, transporterId, getUserRole, transporterUsers, transporterUid, getCompanyName,
    } = getUser();
    const router = useRouter();
    const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const categoryPicked = ref('');
    const vehicleMake = ref('');
    const vehicleReg = ref('');
    const vehicleVIN = ref('');
    const vehicleMakeYear = ref('');
    const makeYear = ref([]);
    const insuranceCompany = ref('');
    const insuranceType = ref('');
    const insuranceStart = ref('');
    const insuranceEnd = ref('');
    const motStart = ref('');
    const motEnd = ref('');
    const driverSex = ref('');
    const driverFullName = ref('');
    const driverDoB = ref('');
    const driverPhone = ref('');
    const driverLicenseType = ref('');
    const driverLicenseNumber = ref('');
    const driverLicenseExpiry = ref('');
    const driverEmail = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const driverRegion = ref('');
    const dateResults = ref('');
    const dobError = ref('');
    const dobErrorStyle = ref('');
    const licenseError = ref('');
    const licenseErrorStyle = ref('');
    const insuranceError = ref('');
    const insuranceErrorStyle = ref('');
    const motError = ref('');
    const motErrorStyle = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    const endMonth = ref('');
    const endDay = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);
    const driverData = ref('');
    const docName = ref(null);

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 19}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date) => {
    /**
     * Get End date from Start Date
     */
      const year = new Date(date).getFullYear() + 1;
      const month = new Date(date).getMonth();
      const day = new Date(date).getDate();
      if (month === 0) {
        endMonth.value = 12;
      } else if (month < 10) {
        endMonth.value = `0${month}`;
      } else {
        endMonth.value = month;
      }
      if (day === 0 && endMonth.value === 12) {
        endDay.value = '31';
      } else if (day < 10) {
        endDay.value = `0${day}`;
      } else {
        endDay.value = day;
      }
      const endDate = `${year}-${endMonth.value}-${endDay.value}`;

      return new Date(endDate);
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      console.log(e.target.name);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.name, fileData: e.target.files },
      ];
      console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    const getVehicleCategories = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').where('status', '==', 1).orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicleCat) => (
              { ...vehicleCat.data(), id: vehicleCat.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    getVehicleCategories();

    // Get the list of make year from current year
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() + 1;
    for (let year = (currentDateYear - 12); year < currentDateYear; year += 1) {
      makeYear.value.push(year);
    }

    const getNumberOfDays = (start, end, type) => {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const today = new Date();
      // const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const age = Math.round(diffInTime / oneDay / 365);
      // const months = Math.round(diffInTime / oneDay / 30);

      // Errors
      // Dob Error
      if (type === 'DoB' && age < 18) {
        dobError.value = 'Driver not old enough';
        dobErrorStyle.value = 'date-error';
      } else {
        dobError.value = '';
        dobErrorStyle.value = '';
      }
      // License Expiry Error
      if (type === 'License' && age < 2) {
        licenseError.value = 'Driver license is less than 2 years';
        licenseErrorStyle.value = 'date-error';
      } else {
        licenseError.value = '';
        licenseErrorStyle.value = '';
      }
      /**
       * Insurance Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'Insurance' && today > date2) {
        insuranceError.value = 'Insurance is not valid';
        insuranceErrorStyle.value = 'date-error';
      } else {
        insuranceError.value = '';
        insuranceErrorStyle.value = '';
      }
      /**
       * Mot Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'MoT') {
        if (today > date2 && date2 > date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        } else if (date2 <= date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        }
      }
    };

    // Submit data
    const submitData = async () => {
      const addData = {
        driverSex: driverSex.value,
        driverFullName: driverFullName.value,
        driverDoB: driverDoB.value,
        addressGPS: addressGPS.value,
        driverEmail: driverEmail.value,
        addressPostal: addressPostal.value,
        driverPhone: driverPhone.value,
        driverRegion: driverRegion.value,
        driverLicenseType: driverLicenseType.value,
        driverLicenseNumber: driverLicenseNumber.value,
        driverLicenseExpiry: new Date(driverLicenseExpiry.value),
        categoryName: categoryPicked.value,
        vehicleMake: vehicleMake.value,
        vehicleReg: vehicleReg.value,
        truckId: vehicleReg.value,
        vehicleVIN: vehicleVIN.value,
        vehicleMakeYear: vehicleMakeYear.value,
        insuranceCompany: insuranceCompany.value,
        insuranceType: insuranceType.value,
        insuranceStart: new Date(insuranceStart.value),
        insuranceEnd: getEndDate(insuranceStart.value),
        motStart: new Date(motStart.value),
        motEnd: getEndDate(motStart.value),
        ownerFullName: getCompanyName.value,
        ownerEmail: user.value.email,
        uid: user.value.uid,
        transporterId: transporterId.value,
        status: 1,
        jobStatus: 0,
        createdDate: timestamp(),
        updatedDate: timestamp(),
      };
      await pentermFirestore.collection('transporterVehicles').add(addData)
        .then((doc) => {
          console.log(doc.id);
          /**
           * Bulk upload files
           */
          const images = ref([]);
          imageFiles.value.forEach((data) => {
            const imageName = `${uuidv4()}.jpeg`;
            images.value = [...images.value, { docType: data.fieldName, imageFile: imageName }];
            uploadFile(data.fileData[0], imageName);
            // console.log(images.value);
          });
          const updateVehicleRef = pentermFirestore.collection('transporterVehicles').doc(doc.id);
          updateVehicleRef.update({
            documents: images.value,
          });
        });

      // Add user to team
      transporterUsers.value = [...transporterUsers.value, {
        fullName: driverFullName.value, email: driverEmail.value, role: 'driver',
      }];
      const addUserRef = pentermFirestore.collection('accountTypeACL').doc(transporterUid.value);
      addUserRef.update({
        users: transporterUsers.value,
      }, ({ merge: true }));
      // End
      /** End */
      router.push({ name: 'TransporterVehicles' });
    };
    // submitData();
    if (!fberror.value) {
      // console.log('Added to db');
      // Clear fields after submission

      // Redirect after adding data
      // router.push({ name: 'Transporters' });
    }
    // end

    const uploadImages = () => {
      /**
       * Bulk upload files
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        // console.log(data[0]);
        const imageName = `${uuidv4()}.jpeg`;
        images.value = [...images.value, imageName];
        uploadFile(data.fileData[0], imageName);
        // console.log(images.value);
      });
    };

    // Check if driver assigned already exist
    const getdriverData = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles').where('driverEmail', '==', driverEmail.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            driverData.value = snap.docs.map((driver) => (
              { ...driver.data(), id: driver.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    onMounted(() => {
      getDate();
      // getEndDate();
      console.log(docName.value.name);

      if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      user,
      getCompanyName,
      docName,
      driverData,
      getdriverData,
      minDate,
      maxDate,
      dobMaxYear,
      getEndDate,
      router,
      getNumberOfDays,
      submitData,
      dobError, // dob error
      dobErrorStyle, // css
      licenseError,
      licenseErrorStyle, // css
      motError,
      motErrorStyle, // css
      insuranceError,
      insuranceErrorStyle,
      dateResults,
      driverLicenseType,
      driverLicenseNumber,
      driverLicenseExpiry,
      driverRegion,
      dataResults,
      dump,
      error,
      publicPath,
      categoryPicked,
      makeYear,
      vehicleMake,
      vehicleReg,
      vehicleVIN,
      vehicleMakeYear,
      insuranceCompany,
      insuranceType,
      insuranceStart,
      insuranceEnd,
      motStart,
      motEnd,
      driverSex,
      driverFullName,
      driverDoB,
      driverPhone,
      driverEmail,
      addressGPS,
      addressPostal,
      fileUpload,
      uploadImages,

    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}
.required-field{
  color: #cc0000;
}

</style>
