<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
<div class="container">
  <h1 class="display-6 text-start" style="font-size: 130%">My Jobs</h1>
  <small>Login to mobile app to update job details.</small>
</div>
<div v-if="dataResults">
  <div class="card-body" >
    <table class="table table-hover text-start">
  <thead>
    <tr>
      <th scope="col">&nbsp;</th>
      <th scope="col">Customer</th>
      <th scope="col">Pickup</th>
      <th scope="col">Distance</th>
      <th scope="col">Dropoff</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="trip in dataResults" :key="trip.id">
        <td>
            <span style="font-size: 1em; color: CornFlowerBlue;" v-if="trip.status === 0">
            <i class="fas fa-plus-circle"></i>
        </span>
        <span style="font-size: 1em; color: Gold;" v-if="trip.status === 1">
            <i class="fas fa-clock"></i>
        </span>
        <span style="font-size: 1em; color: Green;" v-if="trip.status === 2">
            <i class="fas fa-check-circle"></i>
        </span>
        </td>
      <td>
        <div class="job-customer">{{ trip.customer }}</div>
        <div class="job-date">{{ trip.truckRegistration}}</div>
        <div class="job-date">{{ trip.createdDate.toDate().toDateString() }}</div>
      </td>
      <td>
        <div>
          {{ trip.pickup }}
        </div>
        <div class="job-date">
          {{ trip.pickupDate.toDate().toDateString() }}
        </div>
      </td>
      <td>
        <div>{{ trip.distance }}</div>
        <div>
          <span style="font-size: 1em; color: Tomato;">
            <i class="fas fa-arrow-right"></i>
          </span>
        </div>
      </td>
      <td>{{ trip.dropoff }}</td>
    </tr>
  </tbody>
</table>
  </div>
</div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Jobs',
  props: [''],
  setup() {
    const { user, transporterId } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const dump = ref('');
    let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = 25;

    // end
    const jobs = async () => {
      try {
        const res = await pentermFirestore.collection('transporterJobs').where('transporterId', '==', transporterId.value)
          .where('driverEmail', '==', user.value.email)
          .orderBy('updatedDate', 'desc')
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    // Next Pagination
    const nextJobs = async () => {
      try {
        const res = await pentermFirestore.collection('transporterJobs').where('transporterId', '==', transporterId.value)
          .orderBy('createdDate', 'desc').startAfter(last.data().createdDate)
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            // records.value = allRecords.value - snap.docs.length;
            // console.log(last.data());
            // console.log(snap.docs.length % fetchData);
            if (snap.docs.length % fetchData !== 0) {
              disableBtn.value = true;
            }
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    // Previous Pagination
    const previousJobs = async () => {
      try {
        const res = await pentermFirestore.collection('transporterJobs').where('transporterId', '==', transporterId.value)
          .orderBy('createdDate', 'desc').endBefore(last.data().createdDate)
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            // console.log('Previous', snap.docs.length);
            // console.log(Number(snap.docs.length % fetchData));
            if (Number(snap.docs.length % fetchData) !== 0) {
              disableBtn.value = false;
              jobs();
            }
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    onMounted(() => {
      jobs();
    });
    return {
      user,
      loggedInUser,
      dataResults,
      nextJobs,
      previousJobs,
      records,
      disableBtn,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  padding: 2px;
}
</style>
