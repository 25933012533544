<template>
<div class="row-penterm-white">
  <div class="px-4 pt-5 text-center border-bottom">
    <h1 class="display-5">Who We Are</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">
        Quickly design and customize responsive mobile-first sites with Bootstrap,
        the world’s most popular front-end open source toolkit, featuring Sass variables and mixins,
        responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">

      </div>
    </div>
  </div>
</div>
<!--  -->
<div class="row-penterm-red">
  <h1 class="display-5">What We Do</h1>
</div>
</template>
