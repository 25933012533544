<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Job Board</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <router-link class="btn btn-primary lift"
            :to="{ name: 'PentermJobReport'}">
              Reports
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<div class="container-fluid" v-if="dataResults">
  <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total Jobs</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 200 </span>

              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Booked Jobs</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                  100
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">In Progress</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 50 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-warning mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Cancelled</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 10 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-trash text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- Job list below -->
  <div class="row">
      <div class="col-12">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Recent Jobs</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
                <input type="text" class="form-control" ref="datePicker" v-model="dateRange"
                placeholder="Date Range" data-flatpickr='{"mode": "range"}'>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a href="#!" class="btn btn-sm btn-white" @click="filterJobs"> Filter </a>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a href="#!" class="btn btn-sm btn-white" @click="jobs">Clear Filter </a>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Customer
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Status
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Pick Up
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Drop Off
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Distance
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Revenue
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-date"
                    >
                      Pick up date
                    </a>
                  </th>
                  <th>Vehicle</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="job in dataResults" :key="job.id">
                  <td class="goal-project">{{ job.customer }}</td>
                  <td class="goal-status">
                    <div v-if="job.status === 0">
                      <span class="text-primary">●</span> Booked
                    </div>
                    <div v-if="job.status === 1">
                      <span class="text-warning">●</span> In Progress
                    </div>
                    <div v-if="job.status === 2">
                      <span class="text-success">●</span> Completed
                    </div>
                  </td>
                  <td class="goal-progress">
                    {{ job.pickup }}
                  </td>
                  <td class="goal-date">
                    {{ job.dropoff }}
                  </td>
                  <td class="text-start">
                    {{ job.distance }}
                  </td>
                  <td class="text-start">
                    GH&cent; {{ job.finalRevenue }}
                  </td>
                  <td class="text-start">
                    <time datetime="2018-10-24">{{ job.pickupDate.toDate().toDateString() }}</time>
                  </td>
                  <td class="text-start">
                    {{ job.truckId }}
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'PentermJobDetail', params: { id: job.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                        <router-link class="dropdown-item" v-if="job.status < 2"
                        :to="{ name: 'PentermJobEdit', params: { id: job.id } }">
                          <span class="fe fe-edit text-muted mb-0"></span> Edit
                        </router-link>
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobTracker', params: { id: job.id } }">
                          <span class="fe fe-map-pin text-muted mb-0"></span> Track
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import flatpickr from 'flatpickr';
// import { useRouter } from 'vue-router';
// import * as firebase from 'firebase/app';
// import Timestamp from 'firebase/firestore';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Jobs',
  props: [''],
  setup() {
    // const { user, userDetails, getUserRole } = getUser();
    const { user } = getUser();
    // const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const dump = ref('');
    let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = ref(25);
    const datePicker = ref(null);
    const dateRange = ref('');

    // end
    const getDateRange = () => {
      console.log(dateRange.value);
      const dateRangeValue = dateRange.value.split(' ');
      console.log(dateRangeValue);
      console.log('start date', dateRangeValue[0]);
      console.log('end date', dateRangeValue[2]);
    };
    // const startDate = firebase.firestore.Timestamp.fromDate(new Date('2021-07-01'));
    // const endDate = firebase.firestore.Timestamp.fromDate(new Date('2021-07-31'));
    // console.log('unix', startDate);

    const filterJobs = async () => {
      const dateRangeValue = dateRange.value.split(' ');
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[0]));
      const endDate = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[2]));
      try {
        const res = await pentermFirestore.collection('jobs').where('pickupDate', '>', startDate)
          .where('pickupDate', '<', endDate)
          // .orderBy('createdDate', 'desc')
          .limit(fetchData.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    const jobs = async () => {
      // const start = new Date('2021-08-01').getTime() / 1000;
      // const start = Date.now();
      // const end = new Date('2021-08-31').getTime() / 1000;
      // .where('createdDate', '<=', '1630723231251')
      try {
        const res = await pentermFirestore.collection('jobs')
          .orderBy('createdDate', 'desc').limit(fetchData.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    // Next Pagination
    const nextJobs = async () => {
      try {
        const res = await pentermFirestore.collection('jobs')
          .orderBy('createdDate', 'desc').startAfter(last.data().createdDate)
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            // records.value = allRecords.value - snap.docs.length;
            // console.log(last.data());
            // console.log(snap.docs.length % fetchData);
            if (snap.docs.length % fetchData !== 0) {
              disableBtn.value = true;
            }
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    // Previous Pagination
    const previousJobs = async () => {
      try {
        const res = await pentermFirestore.collection('jobs')
          .orderBy('createdDate', 'desc').endBefore(last.data().createdDate)
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            // console.log('Previous', snap.docs.length);
            // console.log(Number(snap.docs.length % fetchData));
            if (Number(snap.docs.length % fetchData) !== 0) {
              disableBtn.value = false;
              jobs();
            }
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    onMounted(() => {
      jobs();
      flatpickr(datePicker.value, { mode: 'range' });
      // if (getUserRole.value === 'admin') {
      //   jobs();
      // } else if (getUserRole.value === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });
    return {
      user,
      loggedInUser,
      dataResults,
      jobs,
      nextJobs,
      previousJobs,
      records,
      disableBtn,
      datePicker,
      dateRange,
      getDateRange,
      filterJobs,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  padding: 2px;
}
</style>
