import { ref } from 'vue';
import firebase from 'firebase';
import { pentermAuth, pentermFirestore } from '../firebase/config';

const user = ref(pentermAuth.currentUser);
const adminStatus = ref(null);
const getAccountType = ref(null);
const getCompanyName = ref(null);
const getUserRole = ref(null);
const transporterId = ref(null);
const transporterUid = ref(null);
const transporterTIN = ref(null);
const transporterUsers = ref(null);
const transporterStatus = ref(null);
const vehicleCategory = ref(null);
const userDetails = ref(null);
// const user = ref(pentermAuth.currentUser)

pentermAuth.onAuthStateChanged((_user) => {
//   console.log('User state change. current user is ', _user);
  if (window.location.hostname === 'localhost') {
    firebase.functions().useEmulator('localhost', 5001);
  }
  user.value = _user;
  // User Custom Claims
  if (_user) {
    pentermAuth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        userDetails.value = idTokenResult.claims;
      });

    // Get Account Details
    const account = async () => {
      try {
        await pentermFirestore.collection('accountTypeACL').get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            // look for user in the list of users for a specified company
              if (doc.data().accountType === 'fleetOperator') {
                doc.data().users.forEach((usr) => {
                  if (usr.email === _user.email) {
                    transporterUid.value = doc.id;
                    transporterTIN.value = doc.data().companyTIN;
                    transporterId.value = doc.data().transporterId;
                    getAccountType.value = doc.data().accountType;
                    vehicleCategory.value = doc.data().vehicleCategory;
                    getCompanyName.value = doc.data().companyName;
                    getUserRole.value = usr.role;
                    transporterUsers.value = doc.data().users;
                    transporterStatus.value = doc.data().status;
                  }
                });
              } else if (_user.email === doc.data().email) {
                transporterUid.value = doc.id;
                getAccountType.value = doc.data().accountType;
                vehicleCategory.value = doc.data().vehicleCategory;
                transporterStatus.value = doc.data().status;
              }

            //
            });
          })
          .catch((err) => {
            getAccountType.value = err;
          });
      } catch (err) {
        getAccountType.value = err.message;
      }
    };
    account();
  }
});

const getUser = () => ({
  user,
  adminStatus,
  userDetails,
  getAccountType,
  vehicleCategory,
  getCompanyName,
  getUserRole,
  transporterId,
  transporterUsers,
  transporterStatus,
  transporterUid,
});

export default getUser;
