<template>
  <div class="px-4 pt-5 text-center border-bottom">
    <h1 class="display-5">Page Not Found</h1>
    <div class="col-lg-12 mx-auto">
      <p class="lead mb-4">
        You are trying to access a resource without the right permissions.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">

      </div>
    </div>
  </div>
</template>
