<template>
  <div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-2">Work With Us</h1>
    <h1 class="display-4">Put your fleet to work</h1>
    <p class="lead">
      Join our hustle free platform and put your fleet to work,
      earn good money with no worries and 24x7x365 visibilty to what your fleet is doing.
      </p>
  </div>
</template>
