<template>
  <div class="row-penterm-white">
    <div class="container">
      <div class="row welcome">
      <div class="col-xs-12 col-md-6 center-row-content">
        <h1 class="display-5 text-start">For Fleet Owners</h1>
        <h1 class="display-6 text-start font-size">
          <p>
            Connect your trucks to Penterm to ensure better vehicle usage,
            which will help you earn more.
          </p>
          <p>
            Using our AI power platform to ensures good route planning which will help you save time
            and money by reducing your fleet's fuel costs.
          </p>
        </h1>
          <div class="d-grid gap-2 d-md-flex justify-content-md-center">
            <router-link class="nav-link" :to="{ name: 'Signup'}">
              <button class="btn btn-penterm-red" type="button">Join us</button>
            </router-link>
          </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <img src="../assets/pexels-kevin-bidwell-2348359.jpg" alt=""
        class="rounded mx-auto d-block img-fluid">
      </div>
    </div>
    </div>
  </div>
<!--  -->
<div class="row-penterm-red">
  <div class="container text-start">
      <div class="container px-4 py-5" id="icon-grid">
    <h2 class="pb-2 border-bottom">Features</h2>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-hand-holding-usd"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Earn More</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-truck"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Advanced Bookings</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-truck"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Fleet Management</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <span style="font-size: 1.75rem;" class="bi text-muted flex-shrink-0 me-3">
          <span style="color: white;">
          <i class="fas fa-chart-line"></i>
          </span>
        </span>
        <div>
          <h4 class="fw-bold mb-0">Advanced Reporting</h4>
          <p>Paragraph of text beneath the heading to explain the heading.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<!--  -->
<div class="row-penterm-white"></div>
</template>

<script>
export default {
  name: 'Shippers',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.font-size{
  font-size: 150%;
}
</style>
