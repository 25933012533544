<template>
<div class="auto-align" >
   <!-- CONTENT
    ================================================== -->
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">

          <!-- Image -->
          <div class="text-center">
            <img src="assets/img/illustrations/20945983.jpg" alt="..." class="img-fluid">
          </div>

        </div>
        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">

          <img src="img/penterm_logo_1.png" alt="">

          <!-- Heading -->
          <h1 class="display-4 text-center mb-3">
            Sign in
          </h1>

          <!-- Subheading -->
          <p class="text-muted text-center mb-5">
            Access to your dashboard.
          </p>

          <!-- Form -->
          <form @submit.prevent="handleSubmit" v-if="!passwordResetVisible">

            <!-- Email address -->
            <div class="form-group text-start">

              <!-- Label -->
              <label class="form-label">
                Email Address
              </label>

              <!-- Input -->
              <input type="email" class="form-control" placeholder="name@address.com"
              v-model="email" required autocomplete="off">

            </div>

            <!-- Password -->
            <div class="form-group text-start">
              <div class="row">
                <div class="col">

                  <!-- Label -->
                  <label class="form-label">
                    Password
                  </label>

                </div>
                <div class="col-auto">

                  <!-- Help text -->
                  <span class="form-text small text-muted show-cursor"
                  @click="showPasswordReset">
                    Forgot password?
                  </span>

                </div>
              </div> <!-- / .row -->

              <!-- Input group -->
              <div class="input-group input-group-merge">

                <!-- Input -->
                <input class="form-control" type="password" placeholder="Enter your password"
                v-model="password" required autocomplete="off">

                <!-- Icon -->
                <span class="input-group-text">
                  <i class="fe fe-eye"></i>
                </span>

              </div>
            </div>

            <!-- Submit -->
            <button class="btn btn-lg w-100 btn-danger mb-3" type="submit">
              Sign in
            </button>

            <!-- Link -->
            <div class="text-center">
              <!-- <small class="text-muted text-center">
                Don't have an account yet?
                <router-link :to="{name: 'Signup'}">Sign up</router-link>.
              </small> -->
            </div>
<!-- <a href="https://www.freepik.com/vectors/cartoon">Cartoon vector created by vectorjuice - www.freepik.com</a> -->
          </form>

          <!-- Password Reset Form -->
          <form @submit.prevent="handlePasswordReset" v-if="passwordResetVisible">

            <!-- Email address -->
            <div class="form-group text-start">

              <!-- Label -->
              <label class="form-label">
                Email Address
              </label>

              <!-- Input -->
              <input type="email" class="form-control" placeholder="name@address.com"
              v-model="email" required autocomplete="off">

            </div>
                <div class="col-auto">

                  <!-- Help text -->
                  <span class="form-text small text-muted text-center show-cursor"
                  @click="showPasswordReset">
                    Login
                  </span>

                </div>

            <!-- Submit -->
            <button class="btn btn-lg w-100 btn-danger mb-3" type="submit">
              Get New Password
            </button>

            <!-- Link -->
            <div class="text-center">
              <small class="text-muted text-center">
                Don't have an account yet?
                <router-link :to="{name: 'Signup'}">Sign up</router-link>.
              </small>
            </div>
<!-- <a href="https://www.freepik.com/vectors/cartoon">Cartoon vector created by vectorjuice - www.freepik.com</a> -->
          </form>

        </div>
      </div> <!-- / .row -->
    </div> <!-- / .container -->
</div>
</template>
<script>
import { ref, onMounted } from 'vue';
// import firebase from 'firebase';
import { useRouter } from 'vue-router';
import useLogin from '../composables/useLogin';
import getUser from '../composables/getUser';

export default {
  name: 'LoginForm',
  setup() {
    const email = ref('');
    const password = ref('');
    const {
      error, login, passwordReset,
    } = useLogin();
    const { user } = getUser();
    const router = useRouter();
    const passwordResetVisible = ref(false);
    // const userAccount = ref('');

    // if (window.location.hostname === 'localhost') {
    //   firebase.functions().useEmulator('localhost', 5001);
    // }

    const handleSubmit = async () => {
      await login(email.value.toLowerCase(), password.value);
      if (!error.value) {
        // context.emit('login');

        router.push({ name: 'TransporterDashboard' });
      }
    };
    const showPasswordReset = () => {
      passwordResetVisible.value = !passwordResetVisible.value;
      error.value = '';
    };

    const handlePasswordReset = async () => {
      await passwordReset(email.value);
      if (!error.value) {
        console.log('Reset email sent');
      } else {
        console.log('error');
      }
    };
    onMounted(() => {
      if (user.value) {
        router.push({ name: 'TransporterDashboard' });
      }
    });

    return {
      email,
      password,
      handleSubmit,
      error,
      passwordResetVisible,
      showPasswordReset,
      handlePasswordReset,
    };
  },
};
</script>
<style scoped>
.page-body {
  background-image: url('../assets/images//gabriel-santos-GBVDilE8yvI-unsplash1.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.auto-align {
  margin: auto;
  margin-top: 10%;
}
.show-cursor {
  cursor: pointer;
}

</style>
