import { createRouter, createWebHistory } from 'vue-router';
// import firebase from 'firebase';
// import Home from '../views/Home.vue';
import Denied from '../views/404.vue';
import Login from '../components/Login.vue';
import Redirect from '../components/LoginRedirect.vue';
import SignUpComplete from '../components/SignUpComplete.vue';
import Signup from '../components/Signup.vue';
import Quotes from '../components/Quotes.vue';
// Penterm
import PentermJobs from '../components/dashboard/penterm/Jobs.vue';
import PentermJobReport from '../components/dashboard/penterm/JobReport.vue';
import PentermJobEdit from '../components/dashboard/penterm/JobEdit.vue';
import PentermJobDetail from '../components/dashboard/penterm/JobDetail.vue';
// Settings
import VehicleCategories from '../components/dashboard/penterm/Pricing.vue';
import PricingSimulator from '../components/dashboard/penterm/PriceSimulator.vue';
// import PentermJobTracker from '../components/dashboard/penterm/JobTracker.vue';
// Transporter
import CompanyAccount from '../components/dashboard/transporter/CompanyAccount.vue';
import Customers from '../components/dashboard/transporter/CustomerList.vue';
import AddCustomer from '../components/dashboard/transporter/Customers.vue';
import TransporterDashboard from '../components/dashboard/transporter/DashBoard.vue';
import NewTransporterVehicle from '../components/vehicles/TransporterVehicleForm.vue';
import TransporterVehicles from '../components/vehicles/Vehicles.vue';
import TransporterVehicleEdit from '../components/vehicles/VehicleEdit.vue';
import TransporterVehicleDetail from '../components/vehicles/VehicleDetail.vue';
import TransporterNewJob from '../components/dashboard/transporter/AddJob.vue';
import TransporterJobs from '../components/dashboard/transporter/Jobs.vue';
import TransporterJobReport from '../components/dashboard/transporter/JobReport.vue';
import TransporterDriverJobs from '../components/dashboard/transporter/DriverJobs.vue';
import TransporterJobEdit from '../components/dashboard/transporter/JobEdit.vue';
import TransporterJobDetail from '../components/dashboard/transporter/JobDetail.vue';
import TransporterJobTracker from '../components/dashboard/transporter/JobTracker.vue';
import TransporterJobDetailPrint from '../components/dashboard/transporter/JobDetailPrint.vue';
import TransporterProfile from '../components/dashboard/transporter/Profile.vue';
import TransporterLoadBoard from '../components/dashboard/transporter/Loadboard.vue';
import TransporterDriverLoadBoard from '../components/dashboard/transporter/DriverLoadboard.vue';
import TransporterLoadBoardEdit from '../components/dashboard/transporter/LoadBoardEdit.vue';
// Owner Form
import VehicleOwner from '../components/vehicles/AddOwnerForm.vue';
import VehicleOwners from '../components/vehicles/VehicleOwnerList.vue';
import VehicleOwnerDetail from '../components/vehicles/VehicleOwnerDetail.vue';
// Loadboard
import Loadboard from '../components/dashboard/loadboard/Jobs.vue';
import LoadboardOld from '../components/dashboard/loadboard/JobsOld.vue';
//
import { pentermAuth } from '../firebase/config';
// auth guard
const requireAuth = (to, from, next) => {
  const user = pentermAuth.currentUser;
  // console.log('current user..is...', user);
  if (!user) {
    next({ name: 'Login' });
  } else {
    next();
  }
};

// if (adminStatus.value === null) {
//   console.log('Router --> false', adminStatus);
// }
// auth guard
// const requireNoAuth = (to, from, next) => {
//   const user = pentermAuth.currentUser;
//   // console.log('current user..is...', user)
//   if (user) {
//     next({ name: 'Home' });
//   } else {
//     next();
//   }
// };
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   // beforeEnter: requireNoAuth,
  // },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/page-not-found',
    name: 'Error',
    component: Denied,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/home',
    name: 'Redirect',
    component: Redirect,
  },
  {
    path: '/activate-account/:email',
    name: 'SignUpComplete',
    component: SignUpComplete,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/shippers',
    name: 'Shippers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Shippers.vue'),
  },
  {
    path: '/haulage',
    name: 'Haulage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Haulage.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Services.vue'),
  },
  {
    path: '/partner',
    name: 'Partner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Partner.vue'),
    // beforeEnter: requireNoAuth,
  },
  {
    path: '/get-quote',
    name: 'Quotes',
    component: Quotes,
    // beforeEnter: requireNoAuth,
  },
  // loadboard
  {
    path: '/loadboard/old',
    name: 'LoadboardOld',
    component: LoadboardOld,
    props: true,
    beforeEnter: requireAuth,
  },
  // penterm
  {
    path: '/dashboard/job-report',
    name: 'PentermJobReport',
    component: PentermJobReport,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/jobs',
    name: 'PentermJobs',
    component: PentermJobs,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/job-edit/:id',
    name: 'PentermJobEdit',
    component: PentermJobEdit,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/job-detail/:id',
    name: 'PentermJobDetail',
    component: PentermJobDetail,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/settings/vehicle-categories',
    name: 'VehicleCategories',
    component: VehicleCategories,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/settings/pricing-simulator',
    name: 'PricingSimulator',
    component: PricingSimulator,
    props: true,
    beforeEnter: requireAuth,
  },
  // End of Penterm Jobs
  {
    path: '/loadboard',
    name: 'Loadboard',
    component: Loadboard,
    props: true,
    beforeEnter: requireAuth,
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: DashBoard,
  //   props: true,
  //   beforeEnter: requireAuth,
  // },
  {
    path: '/new-transporter',
    name: 'CompanyAccount',
    component: CompanyAccount,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/new-customer',
    name: 'AddCustomer',
    component: AddCustomer,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/new-transporter',
    name: 'CompanyAccount',
    component: CompanyAccount,
    props: true,
    beforeEnter: requireAuth,
  },
  // Transporter Menu
  {
    path: '/dashboard/',
    name: 'TransporterDashboard',
    component: TransporterDashboard,
    // props: true
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/vehicles',
    name: 'TransporterVehicles',
    component: TransporterVehicles,
    // props: true
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/new-vehicles',
    name: 'NewTransporterVehicle',
    component: NewTransporterVehicle,
    // props: true
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/vehicle/:id',
    name: 'TransporterVehicleDetail',
    component: TransporterVehicleDetail,
    // props: true
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/vehicles-edit/:id',
    name: 'TransporterVehicleEdit',
    component: TransporterVehicleEdit,
    // props: true
    beforeEnter: requireAuth,
  },
  // End
  {
    path: '/dashboard/vehicles',
    name: 'TransporterVehicles',
    component: TransporterVehicles,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/add-job',
    name: 'TransporterNewJob',
    component: TransporterNewJob,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/jobs',
    name: 'TransporterJobs',
    component: TransporterJobs,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/job-report',
    name: 'TransporterJobReport',
    component: TransporterJobReport,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/driver/jobs',
    name: 'TransporterDriverJobs',
    component: TransporterDriverJobs,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/job-edit/:id',
    name: 'TransporterJobEdit',
    component: TransporterJobEdit,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/loadboard',
    name: 'TransporterLoadBoard',
    component: TransporterLoadBoard,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/driver/loadboard',
    name: 'TransporterDriverLoadBoard',
    component: TransporterDriverLoadBoard,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/loadboard/:id',
    name: 'TransporterLoadBoardEdit',
    component: TransporterLoadBoardEdit,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/customers',
    name: 'Customers',
    component: Customers,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/job-detail/:id',
    name: 'TransporterJobDetail',
    component: TransporterJobDetail,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/job-tracker/:id',
    name: 'TransporterJobTracker',
    component: TransporterJobTracker,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/job-detail-print/:id',
    name: 'TransporterJobDetailPrint',
    component: TransporterJobDetailPrint,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/profile',
    name: 'TransporterProfile',
    component: TransporterProfile,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/owner-list',
    name: 'VehicleOwners',
    component: VehicleOwners,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/new-owner',
    name: 'VehicleOwner',
    component: VehicleOwner,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/owner-detail/:id/:pop',
    name: 'VehicleOwnerDetail',
    component: VehicleOwnerDetail,
    // props: true,
    beforeEnter: requireAuth,
  },
  // { path: '/*', redirect: '/' },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
