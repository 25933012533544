<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Job Tracker</h6>

            <!-- Title -->
            <h1 class="header-title">Job No: {{ dataResult.tripId }}</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <router-link
              class="btn btn-secondary lift"
              :to="{ name: 'TransporterJobs' }"
            >
              Cancel
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <!-- Card -->
    <div class="row">
      <div class="col-sm-12 col-md-10">
        <div class="card">
          <div class="card-body">
            <!-- customer details -->
            <h1 class="display-6 text-start">
              <span class="text-secondary">Customer: </span
              >{{ dataResult.customer }}
            </h1>
            <div class="job-date text-start">
              <i class="fe fe-phone"></i> {{ dataResult.phone }}, &nbsp;
              <i class="fe fe-mail"></i>
              {{ dataResult.email }}.
              <hr />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <h1>
                  {{ dataResult.pickup }}
                  <i class="fe fe-arrow-right"></i>
                  {{ dataResult.dropoff }}
                </h1>
              </div>
            </div>
            <!--  -->
            <!-- Map -->
            <div class="ratio ratio-21x9 overflow-hidden rounded">
              <div ref="mapDivRef"></div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-2">
        <!-- row -->
        <div class="row">
          <div class="card">
            <div class="card-body">
              Contact Driver
            </div>
          </div>
        </div>
        <!-- .row -->
        <!-- row -->
        <div class="row">
          <div class="card">
            <div class="card-body">
              Contact Driver
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
    </div>
  </div>
</template>
<script>
// import { ref } from '@vue/runtime-dom';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import mapboxgl from 'mapbox-gl';
import { pentermFirestore } from '../../../firebase/config';
// import getUser from '../../../composables/getUser';
// import GMap from './GMap.vue';
// Map Box
// const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

export default {
  name: 'JobEdit',
  components: {
    // GMap,
  },
  el: '#app',
  setup() {
    // const { getUserRole } = getUser();
    const error = ref('');
    const dataResult = ref('');
    const status = ref('');
    const route = useRoute();
    // const router = useRouter();
    const cost = ref('');
    const finalRevenue = ref('');
    const finalFuel = ref('');
    const weight = ref('');
    const weightOfBag = ref('');
    const noOfBags = ref('');
    const totalTonnage = ref('');
    const loadType = ref('');
    const expenses = ref('');
    const maintenance = ref('');
    const comments = ref('');
    const truckUid = ref('');
    const pickUpContact = ref('');
    const pickUpContactPhone = ref('');
    const dropOffContact = ref('');
    const dropOffContactPhone = ref('');
    const mapDivRef = ref(null);
    const gMapDivRef = ref(null);
    // const map = ref(null);
    const pickUpLatitude = ref('');
    // const pickUpLongitude = ref('');
    // const dropOffUpLatitude = ref('');
    // const dropOffLongitude = ref('');

    const showMap = async (lng, lat) => {
      try {
        mapboxgl.accessToken = 'pk.eyJ1IjoibG5heWVuZGkiLCJhIjoiY2tzbW9sanR3MGgxZTMycDdmZm9hOGFuZyJ9.MMB0EnmYlp5mDkAPndr-Iw';
        const map = new mapboxgl.Map({
          container: mapDivRef.value,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [lng, lat],
          zoom: 8,
        });
        // Add Marker to Map
        new mapboxgl.Marker({
          color: '#cc0000',
        })
          .setLngLat([lng, lat])
          .addTo(map);
        // End of marker
      } catch (err) {
        console.log('map error', err);
      }
    };

    // Get job selected for editing
    const job = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterJobs')
          .doc(route.params.id)
          .get();
        // console.log('waiting for data');
        // console.log(res.data());
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        dataResult.value = res.data();
        cost.value = dataResult.value.cost;
        weightOfBag.value = dataResult.value.weightOfBag;
        noOfBags.value = dataResult.value.noOfBags;
        totalTonnage.value = dataResult.value.itemWeight;
        totalTonnage.value = dataResult.value.itemWeight;
        loadType.value = dataResult.value.itemWeightDesc;
        expenses.value = dataResult.value.jobExpenses;
        maintenance.value = dataResult.value.jobMaintenace;
        comments.value = dataResult.value.jobComments;
        finalFuel.value = dataResult.value.finalFuel;
        truckUid.value = dataResult.value.truckUid;
        pickUpLatitude.value = dataResult.value.pickupCoordinates;
      } catch (err) {
        error.value = err.message;
      }
      // console.log(pickUpLatitude.value.latitude);
      // console.log(pickUpLatitude.value.longitude);
      showMap(pickUpLatitude.value.longitude, pickUpLatitude.value.latitude);
    };
    job();
    // const map = ref('');

    // const hereMap = (divarea) => {
    //   const platform = new window.H.service.Platform({
    //     apikey: 'koUzhNXKUJ6Mo9CD-IdjiN1cp6ppHj3GnolBPpRx-MI',
    //   });
    //   // Obtain the default map types from the platform object:
    //   const defaultLayers = platform.createDefaultLayers();

    //   // Instantiate (and display) a map object:
    //   map.value = new window.H.Map(
    //     divarea,
    //     defaultLayers.vector.normal.map,
    //     {
    //       zoom: 10,
    //       center: { lat: 52.5, lng: 13.4 },
    //     },
    //   );
    // };

    onMounted(() => {
      job();
      if (dataResult.value) {
        showMap(dataResult.value.pickupCoordinates.longitude,
          dataResult.value.pickupCoordinates.longitude);
      }

      // showMap();
      // hereMap(gMapDivRef.value);
      // if (getUserRole.value === 'admin') {
      //   job();
      // } else if (getUserRole.value === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
      // console.log(dataResult.value.pickupCoordinates);
    });

    return {
      error,
      dataResult,
      status,
      finalRevenue,
      cost,
      finalFuel,
      weight,
      weightOfBag,
      noOfBags,
      totalTonnage,
      loadType,
      expenses,
      maintenance,
      comments,
      pickUpContact,
      pickUpContactPhone,
      dropOffContact,
      dropOffContactPhone,
      mapDivRef,
      gMapDivRef,
      showMap,
      //
      // map,
    };
  },
};
</script>
<style>
</style>
