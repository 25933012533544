<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
<div class="container">
  <h1 class="display-6 text-start">Penterm Loadboard</h1>
    <div class="text-end px-2">
      <button class="btn btn-penterm-default" @click="refreshData">
        <i class="fas fa-sync-alt"></i> Refresh Data
      </button>
  </div>
  <div class="col-xs-6 col-md-6" v-if="!showFilter">
      <div class="text-start px-2">
      <div class="input-group mb-3">
        <label class="px-4" style="margin: auto;">My Trucks</label>
        <select class="form-control" v-model="vehicleSelected">
          <option v-for="vehicle in vehicleCategories" :key="vehicle.id">
            {{ vehicle }}
          </option>
        </select>
        <button class="btn btn-outline-secondary" type="button"
        @click="sortJobs(vehicleSelected)">
          <i class="fas fa-sync-alt"></i>  Sort Data
        </button>
      </div>

    </div>
  </div>
  <div class="col-xs-12 col-md-12" v-if="showFilter">
    <h1 class="display-6">Current Filter: {{ vehicleSelected }}</h1>
    <button class="btn btn-penterm-default" @click="refreshData">
      <i class="fas fa-sync-alt"></i> Clear Filter
    </button>
  </div>

</div>
<div v-if="dataResults">
  <div class="alert alert-danger" role="alert" v-if="acceptanceError">
    {{ acceptanceError }}
  </div>
  <div class="card-body" >
    <table class="table table-hover text-start">
  <thead>
    <tr>
      <th scope="col">Vehicle</th>
      <th scope="col">Pickup</th>
      <th scope="col">Distance</th>
      <th scope="col">Dropoff</th>
      <th scope="col">Price</th>
      <th scope="col">Details</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="trip in dataResults" :key="trip.id">
      <td>
        {{ trip.vehicleType }}
      </td>
      <td>
        <div>
          {{ trip.pickup }}
        </div>
        <div class="job-date">
          {{ trip.pickupDate.toDate().toDateString() }}
        </div>
      </td>
      <td>
        <div>{{ trip.distance }}</div>
        <div>
          <span style="font-size: 1em; color: Tomato;">
            <i class="fas fa-arrow-right"></i>
          </span>
        </div>
      </td>
      <td>{{ trip.dropoff }}</td>
      <td>GH&cent;{{ trip.cost }}</td>
      <td>
        <ul>
          <li class="nav-item">
            <button class="btn btn-primary btn-sm"
            @click="acceptJob(trip.id, trip.vehicleType)">Accept</button>
          </li>
        </ul>
        </td>
    </tr>
  </tbody>
</table>
  </div>
</div>
</template>
<script>
import { onMounted } from 'vue';
import { ref } from '@vue/runtime-dom';
import { useRouter } from 'vue-router';
import getUser from '../../../composables/getUser';
import { timestamp, pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Jobs',
  props: [''],
  setup() {
    const { user, userDetails } = getUser();
    const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const vehicleCategories = ref([]);
    const vehicleSelected = ref('');
    const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    const acceptanceError = ref('');
    const fetchData = 50;
    const showFilter = ref(false);

    // end
    // Get transporter vehicle types

    const pentermVehicles = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('status', '==', 1).where('transporterId', '==', userDetails.value.transporterId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              vehicleCategories.value = [...vehicleCategories.value, doc.data().categoryName];
            });
            // Get unique values from the list of vehicles owned by the transporter
            vehicleCategories.value = [...new Set(vehicleCategories.value)];
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    // end
    // Get Jobs
    const jobs = async () => {
      try {
        await pentermFirestore.collection('loadBoard').where('status', '==', 0)
          // .where('vehicleType', 'in', vehicleCategories.value)
          .orderBy('createdDate', 'desc')
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
          });
        // console.log('data....', dataResults.value);
      } catch (err) {
        error.value = err;
      }
    };

    // // Next Pagination
    // const nextJobs = async () => {
    //   try {
    //     const res = await pentermFirestore.collection('loadBoard').where('status', '==', 0)
    //       .orderBy('createdDate', 'desc').startAfter(last.data().createdDate)
    //       .limit(fetchData)
    //       .onSnapshot((snap) => {
    //         // console.log(snap.docs);
    //         dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
    //         last = snap.docs[snap.docs.length - 1];
    //         // records.value = allRecords.value - snap.docs.length;
    //         // console.log(last.data());
    //         // console.log(snap.docs.length % fetchData);
    //         if (snap.docs.length % fetchData !== 0) {
    //           disableBtn.value = true;
    //         }
    //       });
    //     // console.log('waiting for data');
    //     dump.value = res;
    //     // dataResult.value = res.docs.map((trip)=>{
    //     //   return { ...trip.data(), id: trip.id }
    //     // })
    //   } catch (err) {
    //     // console.log(err.message);
    //     // console.log('Error occured');
    //     error.value = err.message;
    //   }
    // };
    // // Previous Pagination
    // const previousJobs = async () => {
    //   try {
    //     const res = await pentermFirestore.collection('loadBoard').where('status', '==', 0)
    //       .orderBy('updatedDate', 'desc').endBefore(last.data().createdDate)
    //       .limit(fetchData)
    //       .onSnapshot((snap) => {
    //         // console.log(snap.docs);
    //         dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
    //         last = snap.docs[snap.docs.length - 1];
    //         records.value = snap.docs.length;
    //         // console.log(last.data());
    //         // console.log('Previous', snap.docs.length);
    //         // console.log(Number(snap.docs.length % fetchData));
    //         if (Number(snap.docs.length % fetchData) !== 0) {
    //           disableBtn.value = false;
    //           // jobs();
    //         }
    //       });
    //     // console.log('waiting for data');
    //     dump.value = res;
    //     // dataResult.value = res.docs.map((trip)=>{
    //     //   return { ...trip.data(), id: trip.id }
    //     // })
    //   } catch (err) {
    //     // console.log(err.message);
    //     // console.log('Error occured');
    //     error.value = err.message;
    //   }
    // };

    const refreshData = () => {
      dataResults.value = '';
      jobs();
      pentermVehicles();
      showFilter.value = false;
    };

    const sortJobs = (category) => {
      // dataResults.value = '';
      // jobs();
      const obj = dataResults.value.filter((o) => o.vehicleType === category);
      dataResults.value = obj;
      showFilter.value = true;
      // console.log(obj);
    };

    const acceptJob = (jobId, vehicleCat) => {
      // Refresh vehicle list
      if (vehicleCategories.value.length < 1) {
        pentermVehicles();
      }
      // check that transporter has the vehicle type selected
      const checkVehicle = vehicleCategories.value
        .filter((vehicleType) => vehicleType === vehicleCat);
      // If vehicle exists then add to transporters list
      if (checkVehicle.length > 0) {
        const updateJobRef = pentermFirestore.collection('loadBoard').doc(jobId);
        updateJobRef.update({
          transporterId: userDetails.value.transporterId,
          status: 1,
          updatedDate: timestamp(),
          acceptedBy: user.value.uid,
        }).catch((err) => console.log(err));
        // Refresh after accepting job
        refreshData();
        router.push({ name: 'TransporterLoadBoard' });
      } else {
        acceptanceError.value = 'Sorry cannot accept job as you have no vehicles in this category';
      }
    };
    // end

    onMounted(() => {
      jobs();
      pentermVehicles();
    });
    return {
      user,
      vehicleCategories,
      loggedInUser,
      dataResults,
      // nextJobs,
      // previousJobs,
      records,
      disableBtn,
      refreshData,
      acceptJob,
      sortJobs,
      showFilter,
      vehicleSelected,
      acceptanceError,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  padding: 2px;
}
</style>
