<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Job Update</h6>

            <!-- Title -->
            <h1 class="header-title">Job Edit: {{ dataResult.tripId }}</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
             <router-link class="btn btn-secondary lift"
            :to="{ name: 'TransporterJobs'}">
              Cancel
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <!-- Card -->
    <div class="card">
      <div class="card-body">
          <!-- customer details -->
          <h1 class="display-6 text-start">
            <span class="text-secondary">Customer: </span>{{ dataResult.customer }}
          </h1>
          <div class="job-date text-start">
            <i class="fe fe-phone"></i> {{ dataResult.phone }}, &nbsp;<i
              class="fe fe-mail"
            ></i>
            {{ dataResult.email }}.
            <hr>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <h1>{{ dataResult.pickup }}
                <i class="fe fe-arrow-right"></i>
                {{ dataResult.dropoff }}
              </h1>
            </div>
          </div>
          <!--  -->
        <!-- Form -->
        <form @submit.prevent="updateJob" class="text-start">
            <div class="row">
              <div class="col-xs-12 col-md-3">
                <label class="form-label">Job Status</label>
                <select
                  class="form-select"
                  v-model="status"
                  aria-label="Floating label select"
                  required
                >
                  <option value="1" selected>In Progress</option>
                  <option value="2">Completed</option>
                  <option value="3">Cancelled</option>
                </select>
              </div>
              <div class="col-xs-12 col-md-2">
                <label class="form-label">Revenue</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Revenue"
                  required
                  v-model="cost"
                  step=".01"
                />
              </div>
              <div class="col-xs-12 col-md-2">
                <label class="form-label">Fuel Cost</label>
                <input
                  type="numbner"
                  class="form-control"
                  placeholder="Fuel Cost"
                  required
                  v-model="finalFuel"
                  step=".01"
                />
              </div>
              <div class="col-xs-12 col-md-2">
                <label class="form-label">Expenses</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="expenses"
                  step=".01"
                  placeholder="Expenses"
                  required
                />
              </div>
              <div class="col-xs-12 col-md-2">
                <label class="form-label">Maintenance</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="maintenance"
                  step=".01"
                  placeholder="Maintenace"
                  required
                />
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row py-2">
              <div
                class="col-xs-12 col-md-2"
                v-if="dataResult.itemWeightDesc === 'bags'"
              >
                <label class="form-label">No of bags</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="noOfBags"
                  placeholder="No.of bags"
                  required
                />
              </div>
              <!--  -->
              <!--  -->
              <div
                class="col-xs-12 col-md-2"
                v-if="dataResult.itemWeightDesc === 'bags'"
              >
                <label class="form-label">Kg/bag</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="weightOfBag"
                  placeholder="Weight"
                  required
                />
              </div>
              <!--  -->
              <!--  -->
              <div
                class="col-xs-12 col-md-2"
                v-if="dataResult.itemWeightDesc === 'bags'"
              >
                <label class="form-label">Tonnes</label>
                <input
                  type="number"
                  class="form-control"
                  :value="(weightOfBag * noOfBags) / 1000"
                  disabled
                />
              </div>
              <!--  -->
              <div
                class="col-xs-12 col-md-2"
                v-if="dataResult.itemWeightDesc === 'bulk'"
              >
                <label class="form-label">Tonnes</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="totalTonnage"
                  placeholder="Total"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <label class="form-label">Comments/Notes</label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="5"
                  v-model="comments"
                ></textarea>
              </div>
              <!-- contact details -->
              <div class="col-xs-12 col-md-6">
                <div class="row py-2">
                  <div class="col-sm-12 col-md-6">
                    <label class="form-label">Contact at Pick Up</label>
                    <input type="text" class="form-control" v-model="pickUpContact">
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <label class="form-label">Phone</label>
                    <input type="text" class="form-control" v-model="pickUpContactPhone">
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <label class="form-label">Contact at Drop Off</label>
                    <input type="text" class="form-control" v-model="dropOffContact">
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <label class="form-label">Phone</label>
                    <input type="text" class="form-control" v-model="dropOffContactPhone">
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
            <div class="row py-3" v-if="status == 2">
            <div class="col-xs-4 col-md-4">
              <h4 class="text-primary">
                Fuel Receipt
                <span class="text-danger"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoA" name="Fuel Receipt"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <!-- end -->
            </div>
            <!-- .row end -->
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <span class="error">{{ error }}</span>
              </div>
              <div class="col-xs-12 col-md-12 py-2 text-end">
                <button class="btn btn-danger">Save</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { pentermFirestore, timestamp } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'JobEdit',
  setup() {
    const { user, getUserRole } = getUser();
    const error = ref('');
    const dataResult = ref('');
    const status = ref('');
    const route = useRoute();
    const router = useRouter();
    const dump = ref('');
    const cost = ref('');
    const finalRevenue = ref('');
    const finalFuel = ref('');
    const weight = ref('');
    const weightOfBag = ref('');
    const noOfBags = ref('');
    const totalTonnage = ref('');
    const loadType = ref('');
    const expenses = ref('');
    const maintenance = ref('');
    const comments = ref('');
    let finalWeight = 0;
    let finalWeightOfBags = 0;
    let finalBags = 0;
    const truckUid = ref('');
    const pickUpContact = ref('');
    const pickUpContactPhone = ref('');
    const dropOffContact = ref('');
    const dropOffContactPhone = ref('');

    // Get job selected for editing
    const job = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterJobs')
          .doc(route.params.id)
          .get();
        // console.log('waiting for data');
        // console.log(res.data());
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        dataResult.value = res.data();
        cost.value = dataResult.value.cost;
        weightOfBag.value = dataResult.value.weightOfBag;
        noOfBags.value = dataResult.value.noOfBags;
        totalTonnage.value = dataResult.value.itemWeight;
        totalTonnage.value = dataResult.value.itemWeight;
        loadType.value = dataResult.value.itemWeightDesc;
        expenses.value = dataResult.value.jobExpenses;
        maintenance.value = dataResult.value.jobMaintenace;
        comments.value = dataResult.value.jobComments;
        finalFuel.value = dataResult.value.finalFuel;
        truckUid.value = dataResult.value.truckUid;
        pickUpContact.value = dataResult.value.pickUpContact;
        pickUpContactPhone.value = dataResult.value.pickUpContactPhone;
        dropOffContact.value = dataResult.value.dropoffContact;
        dropOffContactPhone.value = dataResult.value.dropoffContactPhone;
      } catch (err) {
        error.value = err.message;
      }
    };
    const updateJob = async () => {
      const jobRef = pentermFirestore
        .collection('transporterJobs')
        .doc(route.params.id);
      if (loadType.value === 'bulk') {
        finalWeight = totalTonnage.value;
      } else {
        finalWeight = (weightOfBag.value * noOfBags.value) / 1000;
        finalWeightOfBags = weightOfBag.value;
        finalBags = noOfBags.value;
      }
      // Final Renvenue
      const finalRevenueMade = Number(cost.value)
        - (Number(expenses.value)
          + Number(finalFuel.value)
          + Number(maintenance.value));
      // End
      // Update job details
      const res = await jobRef.update({
        status: Number(status.value),
        finalRevenue: finalRevenueMade,
        cost: cost.value,
        finalFuel: finalFuel.value,
        finalWeightOfBag: finalWeightOfBags,
        finalNoOfBags: finalBags,
        finalItemWeight: finalWeight,
        jobExpenses: expenses.value,
        jobMaintenace: maintenance.value,
        jobComments: comments.value,
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
        updatedByEmail: user.value.email,
      });
      // Update truck status after job is completed
      // const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(truckUid.value);
      // await vehicleRef.update({
      //   jobStatus: 0,
      // });
      // end
      dump.value = res;
      router.push({ name: 'TransporterJobs' });
    };
    job();
    onMounted(() => {
      if (getUserRole.value === 'admin') {
        job();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      error,
      dataResult,
      updateJob,
      status,
      finalRevenue,
      cost,
      finalFuel,
      weight,
      weightOfBag,
      noOfBags,
      totalTonnage,
      loadType,
      expenses,
      maintenance,
      comments,
      pickUpContact,
      pickUpContactPhone,
      dropOffContact,
      dropOffContactPhone,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
}
.job {
  margin: auto;
}
.menu-items {
  display: block;
}
.trips {
  width: 98%;
  max-width: 960px;
  margin: 10px auto;
  border-radius: 4px;
  box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
  background: white;
  padding: 10px;
}
.trips:hover {
  background: #f7f9fb;
  cursor: pointer;
}
.jobdetails {
  display: table-row;
  border: 1px solid #bbb;
}
.pad-body {
  padding: 10px;
  margin: 10px;
}
</style>
