<template>
<!--  -->
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
        <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
<!--  -->
<h4 class="display-6 text-start">Vehicle Edit: {{ vehicleReg }}<hr></h4>
     <form @submit.prevent="updateVehicle" autocomplete="off" class="py-2">
       <div class="row error" v-if="dobError || licenseError || insuranceError">
         <div class="col-xs-12 col-md-12">
          <ul>
            <li v-if="dobError">{{ dobError }}</li>
            <li v-if="licenseError">{{ licenseError }}</li>
            <li v-if="insuranceError">{{ insuranceError }}</li>
          </ul>
         </div>
       </div>
       <h4 class="text-primary text-start text-danger">Driver Details</h4>
        <div class="row text-start py-2">
          <!--  -->
          <div class="col-xs-12 col-md-2">
            <label>Sex</label>
              <select class="form-control" required v-model="driverSex">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-4 text-start">
            <label>Driver Full Name</label>
              <input type="text" class="form-control"
              placeholder="Kofi Manu" required v-model="driverFullName">
            </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Date Of Birth</label>
              <input type="date" class="form-control" :class="[dobErrorStyle]"
              placeholder="DoB" required v-model="driverDoB" :max="dobMaxYear"
              @change="getNumberOfDays(driverDoB, new Date(),'DoB')">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Phone Number</label>
              <input type="tel" class="form-control"
              placeholder="024412345678" required v-model="driverPhone">
          </div>
          <!--  -->
          <!--  -->
          <!-- end of row -->
        </div>
        <div class="row text-start py-2">
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Email</label>
              <input type="email" class="form-control" disabled
              placeholder="[optional]" v-model="driverEmail">
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >License Class</label>
              <select class="form-control" required v-model="driverLicenseType">
                <!-- <option value="B">Class B</option> -->
                <option value="C">Class C</option>
                <option value="D">Class D</option>
                <option value="E">Class E</option>
                <option value="F">Class F</option>
              </select>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >License Number</label>
              <input type="text" class="form-control"
              placeholder="License number" required v-model="driverLicenseNumber">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >License Expiry</label>
              <input type="date" class="form-control"
              placeholder="Expiry date" required v-model="driverLicenseExpiry"
              :class="[licenseErrorStyle]"
              @change="getNumberOfDays(new Date(), driverLicenseExpiry, 'License')">
          </div>
          <!-- End of Row -->
        </div>
        <div class="row text-start py-2">
          <div class="col-xs-12 col-md-3">
            <label >GhanaPost Address</label>
              <input type="text" class="form-control"
              placeholder="GA-123-5432" required v-model="addressGPS">
          </div>
          <div class="col-xs-12 col-md-3">
            <label >Post Box [optional]</label>
              <input type="text" class="form-control"
              placeholder="P.O. Box 1" v-model="addressPostal">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Region</label>
              <select class="form-control"
              placeholder="Region" required v-model="driverRegion">
                <option value="">Select</option>
                <option value="Oti Region">Oti Region</option>
                <option value="Bono East Region">Bono East Region</option>
                <option value="Ahafo Region">Ahafo Region</option>
                <option value="Bono Region">Bono Region</option>
                <option value="North East  Region">North East Region</option>
                <option value="Savannah Region">Savannah Region</option>
                <option value="Western North Region">Western North Region</option>
                <option value="Western Region">Western Region</option>
                <option value="Volta Region">Volta Region</option>
                <option value="Greater Accra Region ">Greater Accra Region </option>
                <option value="Eastern Region">Eastern Region</option>
                <option value="Ashanti Region">Ashanti Region</option>
                <option value="Northern Region">Northern Region </option>
                <option value="Central Region">Central Region</option>
                <option value="Upper East Region">Upper East Region</option>
                <option value="Upper West Region">Upper West Region </option>
              </select>
          </div>
        </div>
        <!-- Row -->
        <!--  -->
    <div class="row py-2 text-start">
      <!-- Vehicle Types -->
      <h4 class="text-primary text-start text-danger">Vehicle Details:</h4>
    </div>
      <div class="row py-2 text-start">
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Manufacturer</label>
              <select class="form-control"
              placeholder="Region" required v-model="vehicleMake">
                <option value="">Select</option>
                <option value="Scania">Scania</option>
                <option value="Hino">Hino</option>
                <option value="Iveco">Iveco</option>
                <option value="MAN">MAN</option>
                <option value="Volvo">Volvo</option>
                <option value="Dongfeng">Dongfeng</option>
                <option value="TATA">TATA</option>
                <option value="Volkswagen">Volkswagen</option>
                <option value="Daimler">Daimler</option>
              </select>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Registration No.</label>
              <input type="text" class="form-control"
              placeholder="GT-1234-10" required v-model="vehicleReg">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Chassis Number</label>
              <input type="text" class="form-control"
              placeholder="ASE123123X231" required v-model="vehicleVIN">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <label >Year</label>
            <select class="form-control"
              placeholder="Year" required v-model="vehicleMakeYear">
              <option v-for="year in makeYear" :key="year" :value="year">{{ year }}</option>
            </select>
            </div>
          </div>
      <div class="row py-2 text-start">
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Insurance Company</label>
              <select class="form-control"
              placeholder="Region" required v-model="insuranceCompany">
                <option value="">Select</option>
                <option value="Activa International">Activa International </option>
                <option value="Allianz Insurance">Allianz Insurance</option>
                <option value="Bedrock Insurance">Bedrock Insurance</option>
                <option value="Best Assurance">Best Assurance</option>
                <option value="Donewell Insurance">Donewell Insurance</option>
                <option value="Enterprise Insurance">Enterprise Insurance</option>
                <option value="Ghana Union Assurance">Ghana Union Assurance </option>
                <option value="Glico General Insurance">Glico General Insurance</option>
                <option value="Hollard Insurance">Hollard Insurance</option>
                <option value="Imperial General Assurance">Imperial General Assurance</option>
                <option value="Loyalty Insurance">Loyalty Insurance </option>
                <option value="Millennium Insurance">Millennium Insurance</option>
                <option value="NSIA Insurance">NSIA Insurance</option>
                <option value="Phoenix Insurance">Phoenix Insurance</option>
                <option value="Prime Insurance ">Prime Insurance</option>
                <option value="Priority Insurance">Priority Insurance</option>
                <option value="Provident Insurance">Provident Insurance</option>
                <option value="Quality Insurance">Quality Insurance</option>
                <option value="RegencyNem Insurance">RegencyNem Insurance</option>
                <option value="Saham Insurance">Saham Insurance</option>
                <option value="Serene Insurance">Serene Insurance</option>
                <option value="SIC Insurance">SIC Insurance</option>
                <option value="Star Assurance">Star Assurance</option>
                <option value="SUNU Assurances">SUNU Assurances</option>
                <option value="Unique Insurance">Unique Insurance</option>
                <option value="Vanguard Assurance">Vanguard Assurance</option>
                <option value="Wapic Insurance">Wapic Insurance</option>
              </select>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Insurance Type</label>
              <select class="form-control"
              placeholder="Region" required v-model="insuranceType">
                <option value="">Select</option>
                <option value="Comprehensive">Comprehensive</option>
                <option value="3rd-Party">3rd-Party</option>
              </select>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Insurance Start</label>
              <input type="date" class="form-control"
              :class="[insuranceErrorStyle]"
              placeholder="Start date" required v-model="insuranceStart"
              :max="maxDate" :min="minDate"
              @change="getNumberOfDays(insuranceStart, insuranceEnd, 'Insurance')">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <label >Road Worthiness Start</label>
              <input type="date" class="form-control"
              :class="[motErrorStyle]"
              placeholder="Start date" required v-model="motStart"
              :max="maxDate" :min="minDate"
              @change="getNumberOfDays(motStart, motEnd, 'MoT')">
          </div>
          </div>
    <h4 class="text-primary text-start text-danger">
      Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
      </h4>
          <div class="row py-2">
          <div class="col-xs-4 col-md-4">
            <h4 class="text-primary">
              Goods in Transit Insurance
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" id="git" name="git" required class="form-control"
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-4">
            <h4 class="text-primary">
              Insurance Certificate
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="insurancePhoto" name="insurancePhoto" required
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-4">
            <h4 class="text-primary">
              Road Worthy Certificate
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="roadWorthyPhoto" name="roadWorthyPhoto" required
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <!-- License -->
          </div>
          <div class="row">
          <div class="col-xs-4 col-md-4">
            <h4 class="text-primary">
              Passport/GhanaCard
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="ghanaID" name="ghanaID" required
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-4">
            <h4 class="text-primary">
              Drivers License Front
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="driverLicensePhotoA" name="driverLicensePhotoA"
                capture="user" accept="image/*" @change="fileUpload" required>
            </label>
          </div>
          <div class="col-xs-4 col-md-4">
            <h4 class="text-primary">
              Drivers License Back
              <span class="required-field"> *</span>
            </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="driverLicensePhotoB" name="driverLicensePhotoB"
                capture="user" accept="image/*" @change="fileUpload" required>
            </label>
          </div>
          <div class="col-xs-4 col-md-3">
            &nbsp;
          </div>
          <div class="col-xs-4 col-md-3">
            &nbsp;
          </div>
          <!--  -->
          </div>
      <!-- Submit -->
              <div class="row">
          <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
          <div class="col-xs-12 col-md-12 text-center">
            <button class="btn btn-danger">Submit</button>
          </div>
        </div>
    <!-- form end -->
      </form>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import { useRoute, useRouter } from 'vue-router';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const { user, getUserRole, transporterId } = getUser();
    const router = useRouter();
    const route = useRoute();
    // const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const categoryPicked = ref('');
    const vehicleMake = ref('');
    const vehicleReg = ref('');
    const vehicleVIN = ref('');
    const vehicleMakeYear = ref('');
    const makeYear = ref([]);
    const insuranceCompany = ref('');
    const insuranceType = ref('');
    const insuranceStart = ref('');
    const insuranceEnd = ref('');
    const motStart = ref('');
    const motEnd = ref('');
    const driverSex = ref('');
    const driverFullName = ref('');
    const driverDoB = ref('');
    const driverPhone = ref('');
    const driverLicenseType = ref('');
    const driverLicenseNumber = ref('');
    const driverLicenseExpiry = ref('');
    const driverEmail = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const driverRegion = ref('');
    const dateResults = ref('');
    const dobError = ref('');
    const dobErrorStyle = ref('');
    const licenseError = ref('');
    const licenseErrorStyle = ref('');
    const insuranceError = ref('');
    const insuranceErrorStyle = ref('');
    const motError = ref('');
    const motErrorStyle = ref('');
    const userGuard = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    const endMonth = ref('');
    const endDay = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);

    // Function to format dates
    const formatDate = (date) => {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) { month = `0${month}`; }
      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    };

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 19}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date) => {
    /**
     * Get End date from Start Date
     */
      const year = new Date(date).getFullYear() + 1;
      const month = new Date(date).getMonth();
      const day = new Date(date).getDate();
      if (month === 0) {
        endMonth.value = 12;
      } else if (month < 10) {
        endMonth.value = `0${month}`;
      } else {
        endMonth.value = month;
      }
      if (day === 0 && endMonth.value === 12) {
        endDay.value = '31';
      } else if (day < 10) {
        endDay.value = `0${day}`;
      } else {
        endDay.value = day;
      }
      const endDate = `${year}-${endMonth.value}-${endDay.value}`;

      return new Date(endDate);
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.files);
      imageFiles.value = [...imageFiles.value, e.target.files];
      console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Get vehicle selected for editing
    const getVehicle = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles').doc(route.params.id).get();
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        // end
        driverFullName.value = res.data().driverFullName;
        driverSex.value = res.data().driverSex;
        driverDoB.value = res.data().driverDoB;
        driverPhone.value = res.data().driverPhone;
        driverEmail.value = res.data().driverEmail;
        addressGPS.value = res.data().addressGPS;
        addressPostal.value = res.data().addressPostal;
        driverRegion.value = res.data().driverRegion;
        driverLicenseType.value = res.data().driverLicenseType;
        driverLicenseNumber.value = res.data().driverLicenseNumber;
        driverLicenseExpiry.value = formatDate(res.data().driverLicenseExpiry.toDate());
        driverRegion.value = res.data().driverRegion;
        vehicleMake.value = res.data().vehicleMake;
        vehicleReg.value = res.data().vehicleReg;
        vehicleVIN.value = res.data().vehicleVIN;
        vehicleMakeYear.value = res.data().vehicleMakeYear;
        insuranceCompany.value = res.data().insuranceCompany;
        insuranceType.value = res.data().insuranceType;
        insuranceStart.value = formatDate(res.data().insuranceStart.toDate());
        insuranceEnd.value = getEndDate(formatDate(res.data().insuranceStart.toDate()));
        motStart.value = formatDate(res.data().motStart.toDate());
        motEnd.value = getEndDate(formatDate(res.data().motStart.toDate()));
        userGuard.value = res.data().uid;
      } catch (err) {
        error.value = err.message;
      }
    };

    // Get the list of make year from current year
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() + 1;

    for (let year = (currentDateYear - 12); year < currentDateYear; year += 1) {
      makeYear.value.push(year);
    }

    const getNumberOfDays = (start, end, type) => {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const today = new Date();
      // const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const age = Math.round(diffInTime / oneDay / 365);
      // const months = Math.round(diffInTime / oneDay / 30);

      // Errors
      // Dob Error
      if (type === 'DoB' && age < 18) {
        dobError.value = 'Driver not old enough';
        dobErrorStyle.value = 'date-error';
      } else {
        dobError.value = '';
        dobErrorStyle.value = '';
      }
      // License Expiry Error
      if (type === 'License' && age < 2) {
        licenseError.value = 'Driver license is less than 2 years';
        licenseErrorStyle.value = 'date-error';
      } else {
        licenseError.value = '';
        licenseErrorStyle.value = '';
      }
      /**
       * Insurance Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'Insurance' && today > date2) {
        insuranceError.value = 'Insurance is not valid';
        insuranceErrorStyle.value = 'date-error';
      } else {
        insuranceError.value = '';
        insuranceErrorStyle.value = '';
      }
      /**
       * Mot Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'MoT') {
        if (today > date2 && date2 > date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        } else if (date2 <= date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        }
      }
    };

    // Submit update
    const updateVehicle = async () => {
      // ensure only the specified user can edit the data
      /**
       * Upload images
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        const imageName = `${uuidv4()}.jpg`;
        images.value = [...images.value, imageName];
        uploadFile(data[0], imageName);
      });
      // end of upload
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(route.params.id);
        await vehicleRef.update({
          driverSex: driverSex.value,
          driverFullName: driverFullName.value,
          driverDoB: driverDoB.value,
          addressGPS: addressGPS.value,
          addressPostal: addressPostal.value,
          driverPhone: driverPhone.value,
          driverRegion: driverRegion.value,
          driverLicenseType: driverLicenseType.value,
          driverLicenseNumber: driverLicenseNumber.value,
          driverLicenseExpiry: new Date(driverLicenseExpiry.value),
          vehicleMake: vehicleMake.value,
          vehicleReg: vehicleReg.value,
          truckId: vehicleReg.value,
          vehicleVIN: vehicleVIN.value,
          vehicleMakeYear: vehicleMakeYear.value,
          insuranceCompany: insuranceCompany.value,
          insuranceType: insuranceType.value,
          insuranceStart: new Date(insuranceStart.value),
          insuranceEnd: getEndDate(insuranceStart.value),
          motStart: new Date(motStart.value),
          motEnd: getEndDate(insuranceStart.value),
          documents: images.value,
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        router.push({ name: 'TransporterVehicles' });
      } else {
        router.push({ name: 'Error' });
      }
    };

    getVehicle();

    onMounted(() => {
      getDate();
      if (getUserRole.value === 'admin') {
        getVehicle();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      user,
      router,
      minDate,
      maxDate,
      dobMaxYear,
      fileUpload,
      getNumberOfDays,
      updateVehicle,
      dobError, // dob error
      dobErrorStyle, // css
      licenseError,
      licenseErrorStyle, // css
      motError,
      motErrorStyle, // css
      insuranceError,
      insuranceErrorStyle,
      dateResults,
      driverLicenseType,
      driverLicenseNumber,
      driverLicenseExpiry,
      driverRegion,
      dataResults,
      dump,
      error,
      publicPath,
      categoryPicked,
      makeYear,
      vehicleMake,
      vehicleReg,
      vehicleVIN,
      vehicleMakeYear,
      insuranceCompany,
      insuranceType,
      insuranceStart,
      insuranceEnd,
      motStart,
      motEnd,
      driverSex,
      driverFullName,
      driverDoB,
      driverPhone,
      driverEmail,
      addressGPS,
      addressPostal,

    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}

</style>
