<template>
<!--  -->
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Fleet Owner</h6>

            <!-- Title -->
            <h1 class="header-title">Registration Form</h1>
          </div>
          <div class="col-auto">
            <router-link
                class="btn btn-secondary lift"
                :to="{ name: 'VehicleOwners' }"
              >
                Cancel
              </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitData" autocomplete="off" class="py-2">
       <h2 class=" text-start text-danger">Owner Details</h2>
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-2">
            <div class="form-floating mb-2">
              <select class="form-control" required v-model="ownerSex">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
              <label>Sex <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-4">
            <div class="form-floating mb-4">
              <input type="text" class="form-control"
              required v-model="ownerFullName">
              <label>Owner full name <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="date" class="form-control"
              required v-model="ownerDoB">
              <label>Date Of Birth <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="tel" class="form-control"
              required v-model="ownerPhone">
              <label>Phone Number <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <!-- end of row -->
        </div>
        <div class="row">
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="email" class="form-control"
              v-model="ownerEmail" required>
              <label>Email <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
<div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              required v-model="addressGPS">
              <label>GhanaPost Address <span class="required-field"> *</span></label>
            </div>
          </div>
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              v-model="addressPostal">
              <label>Post Box [optional]</label>
            </div>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <select class="form-control"
              placeholder="Region" required v-model="ownerRegion">
                <option value="">Select</option>
                <option value="Oti Region">Oti Region</option>
                <option value="Bono East Region">Bono East Region</option>
                <option value="Ahafo Region">Ahafo Region</option>
                <option value="Bono Region">Bono Region</option>
                <option value="North East  Region">North East Region</option>
                <option value="Savannah Region">Savannah Region</option>
                <option value="Western North Region">Western North Region</option>
                <option value="Western Region">Western Region</option>
                <option value="Volta Region">Volta Region</option>
                <option value="Greater Accra Region ">Greater Accra Region </option>
                <option value="Eastern Region">Eastern Region</option>
                <option value="Ashanti Region">Ashanti Region</option>
                <option value="Northern Region">Northern Region </option>
                <option value="Central Region">Central Region</option>
                <option value="Upper East Region">Upper East Region</option>
                <option value="Upper West Region">Upper West Region </option>
              </select>
              <label>Region <span class="required-field"> *</span></label>
            </div>
          </div>
          <!-- End of Row -->
        </div>
        <!--  -->
    <div class="row">
      <!-- Vehicle Types -->
      <h2 class=" text-start text-danger">Next Of Kin Details:</h2>
      <div class="row py-2">
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              required v-model="nokFullName">
              <label>Full Name <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              required v-model="nokPhone">
              <label>Phone Number <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="text" class="form-control"
              required v-model="nokEmail">
              <label>Email <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
            <div class="form-floating mb-3">
              <input type="date" class="form-control"
              required v-model="nokDoB" :max="dobMaxYear">
              <label>Date Of Birth <span class="required-field"> *</span></label>
            </div>
          </div>
          <!--  -->
          </div>
    </div>
    <h2 class=" text-start text-danger">
      Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
      </h2>
          <div class="row py-2">
          <div class="col-xs-4 col-md-6">
            <h4 class="">
              Owner Passport/GhanaCard
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" id="git" name="git" required class="form-control"
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-6">
            <h4 class="">
              Owner Voters ID/License
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="insurancePhoto" name="insurancePhoto" required
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <!-- License -->
          </div>
          <div class="row py-2">
          <div class="col-xs-4 col-md-6">
            <h4 class="">
              Next Of Kin Passport/GhanaCard
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="ghanaID" name="ghanaID" required
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-6">
            <h4 class="">
              Next Of Kin Voters ID/License
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="ownerLicensePhotoA" name="ownerLicensePhotoA"
                capture="user" accept="image/*" @change="fileUpload" required>
            </label>
          </div>
          <div class="col-xs-4 col-md-3">
            &nbsp;
          </div>
          <div class="col-xs-4 col-md-3">
            &nbsp;
          </div>
          <!--  -->
          </div>
      <!-- Submit -->
              <div class="row">
          <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
          <div class="col-xs-6 col-md-6 text-center">
              <router-link
                class="btn btn-secondary lift"
                :to="{ name: 'VehicleOwners' }"
              >
                Cancel
              </router-link>
          </div>
          <div class="col-xs-6 col-md-6 text-center">
            <button class="btn btn-danger">Submit</button>
          </div>
        </div>
    <!-- form end -->
      </form>
        </div>
    </div>
  </div>
<!--  -->
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
// import {
//   getStorage, useStorageEmulator, ref as imgRef, uploadBytes,
// } from 'firebase/storage';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const {
      user, userDetails, getUserRole, getCompanyName,
    } = getUser();
    const router = useRouter();
    const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const makeYear = ref([]);
    const ownerSex = ref('');
    const ownerFullName = ref('');
    const ownerDoB = ref('');
    const ownerPhone = ref('');
    const ownerEmail = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const ownerRegion = ref('');
    const nokFullName = ref('');
    const nokPhone = ref('');
    const nokDoB = ref('');
    const nokEmail = ref('');
    const dateResults = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    const endMonth = ref('');
    const endDay = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date) => {
    /**
     * Get End date from Start Date
     */
      const year = new Date(date).getFullYear() + 1;
      const month = new Date(date).getMonth();
      const day = new Date(date).getDate();
      if (month === 0) {
        endMonth.value = 12;
      } else if (month < 10) {
        endMonth.value = `0${month}`;
      } else {
        endMonth.value = month;
      }
      if (day === 0 && endMonth.value === 12) {
        endDay.value = '31';
      } else if (day < 10) {
        endDay.value = `0${day}`;
      } else {
        endDay.value = day;
      }
      const endDate = `${year}-${endMonth.value}-${endDay.value}`;

      return new Date(endDate);
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.files);
      imageFiles.value = [...imageFiles.value, e.target.files];
      console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${userDetails.value.transporterId}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Get the list of make year from current year
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() + 1;
    for (let year = (currentDateYear - 12); year < currentDateYear; year += 1) {
      makeYear.value.push(year);
    }

    // Submit data
    const submitData = async () => {
      const addData = {
        ownerSex: ownerSex.value,
        ownerFullName: ownerFullName.value,
        ownerDoB: ownerDoB.value,
        addressGPS: addressGPS.value,
        ownerEmail: ownerEmail.value,
        addressPostal: addressPostal.value,
        ownerPhone: ownerPhone.value,
        ownerRegion: ownerRegion.value,
        nokFullName: nokFullName.value,
        nokEmail: nokEmail.value,
        nokPhone: nokPhone.value,
        nokDoB: nokDoB.value,
        uid: user.value.uid,
        transporterId: userDetails.value.transporterId,
        companyName: getCompanyName.value,
        status: 1,
        createdDate: timestamp(),
        createdBy: user.value.displayName,
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
      };
      await pentermFirestore.collection('transporterVehicleOwners').add(addData)
        .then((doc) => {
          /**
           * Bulk upload files
           */
          const images = ref([]);
          imageFiles.value.forEach((data) => {
            const imageName = `${uuidv4()}.jpeg`;
            images.value = [...images.value, imageName];
            uploadFile(data[0], imageName);
            // console.log(images.value);
          });
          const updateVehicleRef = pentermFirestore.collection('transporterVehicleOwners').doc(doc.id);
          updateVehicleRef.update({
            documents: images.value,
          });
        });
      const aclData = {
        email: ownerEmail.value,
        role: 'vehicleOwner',
        transporterId: userDetails.value.transporterId,
        companyName: getCompanyName.value,
      };
      await pentermFirestore.collection('accountTypeACL').add(aclData);
      /** End */
      router.push({ name: 'VehicleOwners' });
    };
    // submitData();
    if (!fberror.value) {
      // console.log('Added to db');
      // Clear fields after submission

      // Redirect after adding data
      // router.push({ name: 'Transporters' });
    }
    // end

    const uploadImages = () => {
      /**
       * Bulk upload files
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        // console.log(data[0]);
        const imageName = `${uuidv4()}.jpeg`;
        images.value = [...images.value, imageName];
        uploadFile(data[0], imageName);
        console.log(images.value);
      });
    };

    onMounted(() => {
      getDate();
      // getEndDate();
      if (getUserRole.value === 'owner') {
        router.push({ name: 'TransporterOwnerJobs' });
      }
    });

    return {
      user,
      minDate,
      maxDate,
      dobMaxYear,
      getEndDate,
      router,
      submitData,
      dateResults,
      ownerRegion,
      dataResults,
      dump,
      error,
      publicPath,
      ownerSex,
      ownerFullName,
      ownerDoB,
      ownerPhone,
      ownerEmail,
      addressGPS,
      addressPostal,
      nokFullName,
      nokPhone,
      nokEmail,
      nokDoB,
      fileUpload,
      uploadImages,

    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}
.required-field{
  color: #cc0000;
}

</style>
