<template>
  <div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-2">Quote</h1>
    <p class="lead">We offer competitive pricing for your frieght and visibiity all the way.</p>
  </div>
<div class="container">
  <div class="row">
    <div class="col-xs-12 col-md-6">
        <!-- <GoogleMap
  api-key="AIzaSyCa1Vz0AEdCMkFyqn06aKvRAuTEQCaDS5g"
  style="width: 100%; height: 500px"
  :center="center"
  :zoom="13"
  :ref="mapRef"
  >
    <Marker :options="{ position: center }" />
  </GoogleMap> -->
    </div>
    <!-- form start -->
    <div class="col-xs-12 col-md-6">
        <div class="col-xs-12 col-md-12"><span class="error">{{ warning }}</span></div>
      <form @submit.prevent="getDistanceMatrix">
        <div class="row">
          <div class="col-xs-12 col-md-6 form-stack">
            <label>Pickup Location</label>
              <vue-google-autocomplete :country="['gh']"
                ref="pickupLocation"
                types=""
                id="pickup"
                classname="form-control field-margin"
                placeholder="Pickup location"
                required
                v-on:placechanged="getPickupAddressData"
            >
            </vue-google-autocomplete>
          </div>
            <div class="col-xs-12 col-md-6">
              <label>Pickup Date</label>
                <input type="datetime-local"
            v-model="pickupDate" class="form-control field-margin" placeholder="Pick up date">
          </div>
            <div class="col-xs-12 col-md-6">
              <label>Dropoff Location</label>
                <vue-google-autocomplete :country="['gh']"
                  ref="dropoffLocation"
                  types=""
                  id="dropoff"
                  classname="form-control field-margin"
                  placeholder="Dropoff location"
                  required
                  v-on:placechanged="getDropoffAddressData"
              >
              </vue-google-autocomplete>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
          <div class="col-xs-12 col-md-12">

            <button class="btn btn-penterm-default">Submit</button>
          </div>
        </div>
        </form>
    </div>
    <div class="container" v-if="jobDetail">
      <p>{{ jobDetail.status }}</p>
      <p>Distance: {{ jobDetail.rows[0].elements[0].distance.text }}</p>
      <p>Duratation of Trip: {{ jobDetail.rows[0].elements[0].duration.text }}</p>
      <p>Duration in Traffic: {{ jobDetail.rows[0].elements[0].duration_in_traffic.text }}</p>
      <p></p>
    </div>
    <!-- form end -->
  </div>
</div>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';
// import { GoogleMap, Marker } from 'vue3-google-map';

// AIzaSyAQYnPusf-PONkd0wPCIm3p5AKRACXJHZ8
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default defineComponent({
  components: {
    // GoogleMap, Marker,
    VueGoogleAutocomplete,
  },
  setup() {
    const center = { lat: 5.608215, lng: -0.17157929 };
    // const clicked = GoogleMap.addListener('click', (mapsMouseEvent) => {
    //   console.log(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
    // });
    const long = ref('');
    const lat = ref('');
    const pickup = ref('');
    const dropoff = ref('');
    const pickupDate = ref('');
    const dropoffDate = ref('');
    const jobDetail = ref('');
    const pickupLocation = ref(null);
    const dropoffLocation = ref(null);
    const error = ref('');
    const warning = ref('');

    // const initAutocomplete = () => {
    //   // const autocomplete = new google.maps.places.Autocomplete(
    //   //   pickup.value,
    //   //   {
    //   //     types: ['establishment'],
    //   //     componentRestrictions: { country: ['GH'] },
    //   //     fields: ['place_id', 'geometry', 'name'],
    //   //   },
    //   // );
    //   // autocomplete.addListener('place_changed', onPlaceChanged);
    // };

    const getPickupAddressData = (addressData, placeResultData) => {
      pickup.value = {
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
      };
    };

    const getDropoffAddressData = (addressData, placeResultData) => {
      dropoff.value = {
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
      };
    };
    // Client side config
    const { google } = window;
    const getDistanceMatrix = () => {
      const origin = new google.maps.LatLng(pickup.value.lat, pickup.value.lng);
      const destination = new google.maps.LatLng(dropoff.value.lat, dropoff.value.lng);
      const depart = new Date(pickupDate.value);
      // const departTime = depart.getTime() / 1000;
      if (pickup.value.place_id && dropoff.value.place_id) {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            drivingOptions: {
              departureTime: depart,
            },
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              // console.log(`Error was: ${status}`);
              error.value = 'Please ensure a pickup location, date and destination is selected.';
            } else {
              // console.log(response);
              jobDetail.value = response;
            }
          },
        );
      } else {
        warning.value = 'Please ensure a pickup location, date and destination is selected.';
      }
    };
    onMounted(() => {
      console.log('mounted in the composition api!');
      // const googlePlaces = document.createElement('script');
      // googlePlaces.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAQYnPusf-PONkd0wPCIm3p5AKRACXJHZ8&libraries=places');
      // googlePlaces.setAttribute('async');
      // document.head.appendChild(googlePlaces);
      getDistanceMatrix();
      // if (origin && destination) {
      //   getDistanceMatrix(origin, destination);
      // }

      // End of Mounted
    });

    return {
      center,
      long,
      lat,
      pickup,
      dropoff,
      getPickupAddressData,
      getDropoffAddressData,
      pickupDate,
      dropoffDate,
      jobDetail,
      pickupLocation,
      dropoffLocation,
      error,
      warning,
      origin,
      getDistanceMatrix,
    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: 5px;
}
</style>
