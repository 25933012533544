<template>
<!-- <div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div> -->
<div class="container">
  <h1 class="display-6 text-start" style="font-size: 130%">New Customer Registration</h1>
  <!-- customers -->
  <div class="alert alert-danger" role="alert"
  v-if="dataResults.length > 0">
    Customer already exists, change email.
  </div>
  <div class="row">
      <div  class="col-sm-6">
        <form class="row g-3" @submit.prevent="addCustomer">
          <div class="col-md-6 text-start">
            <label class="form-label">Full Name</label>
            <input type="text" class="form-control" v-model="fullName" required autocomplete="off">
          </div>
          <div class="col-md-6 text-start">
            <label class="form-label">Company Name</label>
            <input type="text" class="form-control"
            v-model="companyName" required autocomplete="off">
          </div>
          <div class="col-md-6 text-start">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="email" required
            @keyup="getCustomer" autocomplete="off">
          </div>
          <div class="col-md-6 text-start">
            <label class="form-label">Phone</label>
            <input type="tel" class="form-control" v-model="phone"  required autocomplete="off">
          </div>
          <div class="col-md-6 text-start">
            <label class="form-label">Ghana Post Address</label>
            <input type="tel" class="form-control" v-model="gpsAddress" required autocomplete="off">
          </div>
          <div class="col-md-6 text-start">
            <label class="form-label">Postal Address</label>
            <input type="tel" class="form-control"
            required v-model="postalAddress" autocomplete="off">
          </div>
          <div class="col-md-6 text-start">
            <label class="form-label">Region</label>
            <select class="form-control"
              placeholder="Region" required v-model="customerRegion">
                <option value="">Select</option>
                <option value="Oti Region">Oti Region</option>
                <option value="Bono East Region">Bono East Region</option>
                <option value="Ahafo Region">Ahafo Region</option>
                <option value="Bono Region">Bono Region</option>
                <option value="North East  Region">North East Region</option>
                <option value="Savannah Region">Savannah Region</option>
                <option value="Western North Region">Western North Region</option>
                <option value="Western Region">Western Region</option>
                <option value="Volta Region">Volta Region</option>
                <option value="Greater Accra Region ">Greater Accra Region </option>
                <option value="Eastern Region">Eastern Region</option>
                <option value="Ashanti Region">Ashanti Region</option>
                <option value="Northern Region">Northern Region </option>
                <option value="Central Region">Central Region</option>
                <option value="Upper East Region">Upper East Region</option>
                <option value="Upper West Region">Upper West Region </option>
              </select>
          </div>
          <div class="col-12" v-if="dataResults.length <= 0">
            <button type="submit" class="btn btn-primary">Add Customer</button>
          </div>
        </form>
      </div>
      <!-- Owner List -->
      <div class="col-sm-6">
        <h1 class="display-6 text-start" style="font-size: 110%">Select Vehicle Owner</h1>
        <select class="form-control" v-model="ownerName">
          <option v-for="owner in ownerList" value="" :key="owner">
            {{ owner }}
          </option>
        </select>
        <div class="py-2">
          <button class="btn btn-secondary" @click="filterCustomers(ownerName)">Sort</button>
        </div>

        <table class="table table-hover text-start" v-if="vehicleList">
          <thead>
            <tr>
              <th scope="col">Vehicle</th>
              <th scope="col">Driver</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vehicle in vehicleList" :key="vehicle.id">
              <td>
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" :value="vehicle.id">
                    {{ vehicle.vehicleReg }}
                  </label>
                </div>
              </td>
              <td>
                {{ vehicle.driverFullName }}
              </td>
            </tr>
          </tbody>
          </table>
      </div>
  </div>
<!--  -->
</div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import { useRouter } from 'vue-router';
import getUser from '../../../composables/getUser';
import { timestamp, pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Profile',
  setup() {
    const { user, userDetails } = getUser();
    const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dump = ref('');
    const fullName = ref('');
    const email = ref('');
    const companyName = ref('');
    const phone = ref('');
    const gpsAddress = ref('');
    const postalAddress = ref('');
    const customerRegion = ref('');
    const dataResults = ref([]);
    const vehicleList = ref('');
    const ownerList = ref([]);
    const ownerName = ref('');

    const addCustomer = async () => {
      const customerDetails = {
        fullName: fullName.value,
        email: email.value,
        phone: phone.value,
        gpsAddress: gpsAddress.value,
        postalAddress: postalAddress.value,
        customerRegion: customerRegion.value,
        companyName: companyName.value,
        transporterId: userDetails.value.transporterId,
        createdDate: timestamp(),
        createdBy: user.value.uid,
        createdByName: user.value.displayName,
      };
      await pentermFirestore.collection('transporterCustomers').add(customerDetails);
      email.value = '';
      router.push({ name: 'Customers' });
    };
    const getCustomer = async () => {
      try {
        const res = await pentermFirestore.collection('transporterCustomers').where('transporterId', '==', userDetails.value.transporterId)
          .where('email', '==', email.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((owner) => ({ ...owner.data(), id: owner.id }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((owner)=>{
        //   return { ...owner.data(), id: owner.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    const vehicles = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(50)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            vehicleList.value = snap.docs.map((vehicle) => ({ ...vehicle.data(), id: vehicle.id }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    // Owner List
    const getOwnerList = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('status', '==', 1).where('transporterId', '==', userDetails.value.transporterId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              ownerList.value = [...ownerList.value, doc.data().ownerFullName];
            });
            // Get unique values from the list of vehicles owned by the transporter
            ownerList.value = [...new Set(ownerList.value)];
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    const filterCustomers = (owner) => {
      // dataResults.value = '';
      // jobs();
      const obj = dataResults.value.filter((o) => o.ownerFullName === owner);
      dataResults.value = obj;
      // showFilter.value = true;
      // console.log(obj);
    };

    onMounted(() => {
      vehicles();
      getOwnerList();
      // if (userDetails.value.accessType === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });

    return {
      user,
      loggedInUser,
      dump,
      error,
      fullName,
      email,
      phone,
      gpsAddress,
      postalAddress,
      customerRegion,
      companyName,
      addCustomer,
      getCustomer,
      dataResults,
      vehicleList,
      ownerList,
      ownerName,
      filterCustomers,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.owners{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.owners:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.dashboard-card{
  border: solid #ccc 1px;
  border-radius: 4px;
  margin: 5px;
}
.side-menu {
  height: 40px;
  vertical-align: middle;
  border: 1px solid #efefef;
  margin: 2px;
}
.side-menu a:hover {
  background-color: #ebedef;
  color: #e55353;
}

</style>
