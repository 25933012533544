import { ref } from 'vue';
// import firebase from 'firebase';
import { pentermAuth, pentermFirestore } from '../firebase/config';

const error = ref(null);
const errorLogin = ref(null);
// const adminStatus = ref(null);
// account data
const getAccountType = ref(null);
const getCompanyName = ref(null);
const getUserRole = ref(null);
const transporterId = ref(null);
const transporterUid = ref(null);
const transporterUsers = ref(null);
const vehicleCategory = ref(null);
// end

const login = async (email, password) => {
  error.value = null;

  try {
    const res = await pentermAuth.signInWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Could not complete login');
    }
    // Get Account Details
    const account = async () => {
      try {
        await pentermFirestore.collection('accountTypeACL').get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
            // look for user in the list of users for a specified company
              if (doc.data().accountType === 'fleetOperator') {
                transporterUid.value = doc.id;
                transporterUsers.value = doc.data().users;
                doc.data().users.forEach((usr) => {
                  if (usr.email === email) {
                    transporterId.value = doc.data().transporterId;
                    getAccountType.value = doc.data().accountType;
                    vehicleCategory.value = doc.data().vehicleCategory;
                    getCompanyName.value = doc.data().companyName;
                    getUserRole.value = usr.role;
                  }
                });
              } else if (email === doc.data().email) {
                getAccountType.value = doc.data().accountType;
                vehicleCategory.value = doc.data().vehicleCategory;
              }

            //
            });
          })
          .catch((err) => {
            getAccountType.value = err;
          });
      } catch (err) {
        getAccountType.value = err.message;
      }
    };
    account();
    error.value = null;
    errorLogin.value = null;
    return res;
  } catch (err) {
    // console.log(err.value);
    error.value = 'Incorrect Login credentials';
    errorLogin.value = 'Incorrect Login credentials';
    return error;
  }
};

const passwordReset = async (email) => {
  const actionCodeSettings = {
    url: 'https://admin.pentermlogistics.com/login',
    // iOS: {
    //   bundleId: 'com.example.ios',
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12',
    // },
    handleCodeInApp: true,
    // When multiple custom dynamic link domains are defined, specify which
    // one to use.
    // dynamicLinkDomain: 'example.page.link',
  };
  pentermAuth.sendPasswordResetEmail(email, actionCodeSettings).then(() => {
    // Email sent.
    // console.log('Email sent');
  }).catch(() => {
  // An error happened.
    error.value = 'Problem sending email';
  });
};

const useLogin = () => ({
  error, login, passwordReset, transporterId, getCompanyName, errorLogin,
});

export default useLogin;
