<template>
<div class="loader-center" v-if="!dataResult">
<div class="lds-ripple"><div></div><div></div></div>
</div>
<div class="text-end">
  <span style="font-size: 1.5em; color: Green;" class="cursor px-2" @click="createPDF">
    <i class="far fa-file-pdf"></i>
  </span>
  <!-- <span style="font-size: 1.5em; color: Green;" class="px-2">
    <i class="fas fa-clipboard-list"></i>
  </span>
  <span style="font-size: 1.5em; color: Green;" class="px-2">
    <i class="fas fa-print"></i>
  </span> -->
</div>
<div class="col-xs-12 col-md-12" v-if="dataResult">
<div class="card">
  <div class="card-header text-start text-white bg-secondary">
    Job Reciept
  </div>
  <div class="card-body">
    <h1 class="display-6 text-start">{{ dataResult.customer }}</h1>
    <div class="job-date text-start"><i class="fas fa-phone-alt"></i> {{ dataResult.phone }},
    &nbsp;<i class="fas fa-envelope"></i> {{ dataResult.email }}.
    </div>
    <div class="text-end">Date: {{ dataResult.createdDate.toDate().toDateString() }}</div>
    <table class="table table-hover text-start" ref="tableData">
  <thead>
        <tr>
          <th scope="col" colspan="4">{{dataResult.itemDesc}}&nbsp;
            <span style="font-size: 1em;">
                  <i class="fas fa-weight-hanging"></i> {{ dataResult.itemWeight }} Tonnes
                  - : {{ dataResult.itemWeightDesc }}
                  <div v-if="dataResult.itemWeightDesc === 'bags'">
                    [ {{ dataResult.noOfBags }}bags @{{ dataResult.weightOfBag }}/bag ]
                  </div>
                </span>
          </th>
        </tr>
  </thead>
  <tbody>
    <tr class="text-start">
      <td>From:</td>
      <td colspan="3">{{ dataResult.pickup }}</td>
    </tr>
    <tr>
      <td>To:</td>
      <td colspan="3">{{ dataResult.dropoff }}</td>
    </tr>
    <tr>
      <td>Distance:</td>
      <td colspan="3">{{ dataResult.distance }}</td>
    </tr>
    <!-- <tr class="text-primary">
      <td>Computed Cost:</td>
      <td>&nbsp;</td>
      <td class="text-end">GH&cent;</td>
      <td>{{ dataResult.computedCost }}</td>
    </tr> -->
    <tr>
      <td>Cost of job:</td>
      <td>&nbsp;</td>
      <td class="text-end">GH&cent;</td>
      <td class="text-end">{{ dataResult.cost }}</td>
    </tr>
    <tr>
      <td>VAT 12.5%:</td>
      <td>&nbsp;</td>
      <td class="text-end">GH&cent;</td>
      <td class="text-end">{{ dataResult.cost * 0.125 }}</td>
    </tr>
    <tr>
      <td colspan="2" class="text-end">Total:</td>
      <td class="text-end">GH&cent;</td>
      <td class="text-end">{{ Number(dataResult.cost) + Number(dataResult.cost * 0.125) }}</td>
    </tr>
  </tbody>
    </table>
    </div>
  </div>
</div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';
import { pentermFirestore } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'JobDetail',
  components: { },
  setup() {
    const { getUserRole, getCompanyName } = getUser();
    const router = useRouter();
    const error = ref('');
    const dataResult = ref('');
    const route = useRoute();
    // const router = useRouter();
    const pickupLat = ref('');
    const pickupLng = ref('');
    const tableData = ref(null);
    const pickUp = ref('');
    const dropOff = ref('');
    const distance = ref('');
    const pickUpDate = ref('');
    const cost = ref('');
    const customerName = ref('');

    const job = async () => {
      try {
        const res = await pentermFirestore.collection('transporterJobs').doc(route.params.id).get();
        // console.log('waiting for data');
        // console.log(res.data());
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        dataResult.value = res.data();
        pickupLat.value = dataResult.value.pickupCoordinates.latitude;
        pickupLng.value = dataResult.value.pickupCoordinates.longitude;
        pickUp.value = res.data().pickup;
        pickUpDate.value = res.data().pickupDate.toDate().toDateString();
        dropOff.value = res.data().dropoff;
        distance.value = res.data().distance;
        cost.value = res.data().cost;
        customerName.value = res.data().customer;
        //  Collect fields for update
        // source.value = res.data().source;
        // destination.value = res.data().destination;
        // price.value = res.data().price;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    const createPDF = () => {
      console.log(customerName.value);
      const doc = new JSPDF();

      doc.text(getCompanyName.value, 13, 10);
      doc.text('Official Receipt', 13, 20);
      // doc.text(customerName.value, 10, 30);
      // doc.save('a4.pdf');
      doc.autoTable({
        margin: { top: 30 },
        head: [['Job Details', '', pickUpDate.value]],
        body: [
          ['Customer', customerName.value, ''],
          ['From', pickUp.value, ''],
          ['To', dropOff.value, ''],
          ['Distance', distance.value, ''],
          ['Cost of job', '', cost.value],
          ['VAT 12.5%', '', Number(cost.value * 0.125)],
          [' ', 'Total GHC', (Number(cost.value) + Number(cost.value * 0.125))],
          // ...
        ],
      });

      doc.save(`${customerName.value}.pdf`);
    };

    onMounted(() => {
      if (getUserRole.value === 'admin') {
        job();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
      console.log(tableData.value);
    });
    return { dataResult, createPDF, tableData };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.job {
 margin: auto;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.pad-body {
  padding: 10px;
  margin: 10px;
}
.cursor {
  cursor: pointer;
}
</style>
