<template>
<!-- <div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div> -->
<div class="container">
  <h1 class="display-6 text-start" style="font-size: 130%">My Customers</h1>
      <div class="text-end px-2">
    <router-link :to="{ name: 'AddCustomer'}">
      <button class="btn btn-penterm-default"><i class="far fa-plus-square"></i> New</button>
    </router-link>
  </div>
  <!-- customers -->
<!-- <div class="row">
  <div class="col-sm-6 py-2" v-for="customer in dataResults" :key="customer.id">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-2">
            <span style="font-size: 3rem;">
              <span style="color: Grey;">
              <i class="fas fa-user-circle"></i>
              </span>
            </span>
            </div>
          <div class="col-sm-8 text-start">
             <h5 class="card-title">
               {{ customer.fullName }}
             </h5>
           <div>
             Email: {{ customer.email }}
           </div>
           <div>
             Phone: {{ customer.phone }}
           </div>
          </div>
          <div class="col-sm-2 text-start">
            <div>
                <button class="btn btn-danger">
                  <i class="far fa-edit"></i>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!--  -->
<div class="row">
  <div class="col-sm-12 col-md-12">
    <table class="table table-hover text-start" v-if="dataResults">
  <thead>
    <tr>
      <th scope="col">&nbsp;</th>
      <th scope="col">Full Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="customer in dataResults" :key="customer.id">
      <td>
          <span style="font-size: 1.5rem;">
            <span style="color: Grey;">
            <i class="fas fa-user-circle"></i>
            </span>
          </span>
      </td>
      <td>{{ customer.fullName }}</td>
      <td>{{ customer.email }}</td>
      <td>{{ customer.phone }}</td>
      <td>&nbsp;</td>
    </tr>
  </tbody>
</table>
  </div>
</div>
</div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Profile',
  setup() {
    const { user, userDetails } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dump = ref('');
    const dataResults = ref('');

    const getCustomers = async () => {
      try {
        const res = await pentermFirestore.collection('transporterCustomers').where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    onMounted(() => {
      getCustomers();
    });

    return {
      user,
      loggedInUser,
      dump,
      error,
      dataResults,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.dashboard-card{
  border: solid #ccc 1px;
  border-radius: 4px;
  margin: 5px;
}
.side-menu {
  height: 40px;
  vertical-align: middle;
  border: 1px solid #efefef;
  margin: 2px;
}
.side-menu a:hover {
  background-color: #ebedef;
  color: #e55353;
}

</style>
