<template>
<!-- Jobs -->
<div class="row">
<div class="col-xs-12 col-md-12">
  <div class="card">
    <div class="text-end px-2">
    </div>
    <div class="card-header text-start text-white bg-secondary">
      Assign Driver For Loadboard Trip
    </div>
    <div class="card-body">
      <!-- customer details -->
      <h1 class="display-6 text-start">{{ dataResult.agentName }}</h1>
    <div class="job-date text-start">
      <!-- <i class="fas fa-phone-alt"></i> {{ dataResult.agentPhone }}, -->
    <!-- &nbsp;<i class="fas fa-envelope"></i> {{ dataResult.agentEmail }}. -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-9 text-start">
          <div>
            From: {{ dataResult.pickup }}
            <i class="fas fa-arrow-right"></i> {{ dataResult.dropoff }}
          </div>
          Current Price: GH&cent;{{dataResult.computedCost}}
        </div>
        <div class="col-xs-12 col-md-3 text-end">
            <button class="btn btn-primary" @click="pentermVehicles">Get Drivers</button>
        </div>
      </div>
      <!-- no vehicles in category -->
      <div class="row" v-if="activeVehicles && activeVehicles.length <= 0">
        <div class="col-xs-12 col-md-12">
          <h1 class="display-6">Sorry you have no registered vehicles in this category.</h1>
        </div>
      </div>
      <!-- end -->
    </div>
      <!--  -->
      <form @submit.prevent="updateJob" class="text-start" v-if="activeVehicles.length > 0">
              <div class="row">
                <div class="col-xs-12 col-md-4">
                    <label for="floatingSelectGrid">Vehicle</label>
                    <select class="form-select" v-model="assignedVehicle"
                    id="floatingSelectGrid" aria-label="Floating label select" required>
                    <option v-for="vehicle in activeVehicles" :key="vehicle.id"
                    :value="
                        {
                          truckId: vehicle.truckId,
                          Id: vehicle.id,
                          driverFullName: vehicle.driverFullName,
                          driverPhone: vehicle.driverPhone,
                          driverEmail: vehicle.driverEmail,
                        }"
                    >
                      {{ vehicle.driverFullName }}
                    </option>
                    </select>
                </div>
                <!-- <div class="col-xs-12 col-md-4">
                  <label for="floatingInput">Final Revenue</label>
                  <input type="number" class="form-control"
                  placeholder="Customer Name" required v-model="cost" step=".01">
                </div> -->
                </div>
              <!--  -->
          <!--  -->
            <div class="row">
              <!--  -->
              <div class="col-xs-12 col-md-6 py-2">
                Job Comments
                <p>
                  {{ comments }}
                </p>

              </div>
              <!--  -->
            </div>
            <div class="row">
            <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
            <div class="col-xs-12 col-md-12 field-margin">
                <button class="btn btn-penterm-default">Assign Driver</button>
            </div>
            </div>
        </form>
    </div>
  </div>
</div>
<!--  -->
</div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { pentermFirestore, timestamp } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'JobEdit',
  setup() {
    const {
      user, getUserRole, transporterId, getCompanyName,
    } = getUser();
    const error = ref('');
    const dataResult = ref('');
    const status = ref('');
    const route = useRoute();
    const router = useRouter();
    const dump = ref('');
    const cost = ref('');
    const finalRevenue = ref('');
    const finalFuel = ref('');
    const weight = ref('');
    const weightOfBag = ref('');
    const noOfBags = ref('');
    const totalTonnage = ref('');
    const loadType = ref('');
    const expenses = ref('');
    const maintenance = ref('');
    const comments = ref('');
    const truckUid = ref('');
    const activeVehicles = ref('');
    const vehicleCat = ref('');
    const assignedVehicle = ref('');

    // Get job selected for editing
    const job = async () => {
      try {
        const res = await pentermFirestore.collection('loadBoard').doc(route.params.id).get();
        // console.log('waiting for data');
        // console.log(res.data());
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        dataResult.value = res.data();
        cost.value = 0;
        weightOfBag.value = dataResult.value.weightOfBag;
        noOfBags.value = dataResult.value.noOfBags;
        totalTonnage.value = dataResult.value.itemWeight;
        totalTonnage.value = dataResult.value.itemWeight;
        loadType.value = dataResult.value.itemWeightDesc;
        expenses.value = dataResult.value.jobExpenses;
        maintenance.value = dataResult.value.jobMaintenace;
        comments.value = dataResult.value.jobComments;
        finalFuel.value = dataResult.value.finalFuel;
        truckUid.value = dataResult.value.truckUid;
        vehicleCat.value = dataResult.value.vehicleType;
      } catch (err) {
        error.value = err.message;
      }
    };
    // Get Vehicles
    // Get available vehicles with valid insurance and mot
    const pentermVehicles = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('status', '==', 1).where('transporterId', '==', transporterId.value)
          .where('categoryName', '==', vehicleCat.value)
          .onSnapshot((snap) => {
            activeVehicles.value = snap.docs.map((vehicles) => (
              { ...vehicles.data(), id: vehicles.id }
            ));
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    // End
    // Update Job
    const updateJob = async () => {
      const jobRef = pentermFirestore.collection('loadBoard').doc(route.params.id);

      // Update job details
      const res = await jobRef.update({
        status: 2,
        // cost: cost.value,
        transporterName: getCompanyName.value,
        transporterId: transporterId.value,
        driverPhone: assignedVehicle.value.driverPhone,
        driverFullName: assignedVehicle.value.driverFullName,
        driverEmail: assignedVehicle.value.driverEmail,
        vehicleId: assignedVehicle.value.Id,
        truckId: assignedVehicle.value.truckId,
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
        updatedByEmail: user.value.email,
      });
      // Update truck status after job is completed
      // const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(truckUid.value);
      // await vehicleRef.update({
      //   jobStatus: 0,
      // });
      // end
      dump.value = res;
      router.push({ name: 'TransporterLoadBoard' });
    };
    onMounted(() => {
      if (getUserRole.value === 'admin') {
        job();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      error,
      dataResult,
      updateJob,
      status,
      finalRevenue,
      cost,
      finalFuel,
      weight,
      weightOfBag,
      noOfBags,
      totalTonnage,
      loadType,
      expenses,
      maintenance,
      comments,
      activeVehicles,
      assignedVehicle,
      pentermVehicles,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.job {
 margin: auto;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.pad-body {
  padding: 10px;
  margin: 10px;
}
</style>
